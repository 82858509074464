<template>
  <div class="i18n">
    <select
      v-model="$i18n.locale"
      @change="onLocaleChange($event)"
      class="language_switch"
    >
      <option v-for="lang in langs" :value="lang.code" :key="lang.code">
        {{ lang.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langs: [
        {
          code: "en",
          label: "English",
        },
        {
          code: "ko",
          label: "한국어",
        },
      ],
    };
  },
  methods: {
    onLocaleChange(event) {
      this.$i18n.locale = event.target.value;
    },
  },
};
</script>
