<template>
  <main>
    <div class="container">
      <template v-if="contentLoading">
        <clip-loader
          class="spinner"
          :loading="contentLoading"
          :color="'#2da1dd'"
          :size="'30px'"
        />
      </template>
      <template v-else>
        <div class="notice_write table_wrap">
          <form @submit.prevent="formSubmit" method="post">
            <table class="notice_write_table htable">
              <tr>
                <th>구분</th>
                <td>
                  <input
                    type="radio"
                    name="radio"
                    value="N"
                    id="search_category_N"
                    v-model="search_category"
                  />
                  <label for="search_category_N">일반</label>
                  <input
                    type="radio"
                    name="radio"
                    value="Y"
                    id="search_category_Y"
                    v-model="search_category"
                  />
                  <label for="search_category_Y">공지</label>
                </td>
              </tr>
              <tr class="notice_write_title">
                <th>제목</th>
                <td>
                  <ValidationProvider
                    name="제목"
                    rules="required"
                    ref="refTitle"
                  >
                    <input
                      type="text"
                      name="title"
                      id="title"
                      v-model="title"
                    />
                  </ValidationProvider>
                </td>
              </tr>
              <tr class="notice_write_content">
                <th>내용</th>
                <td>
                  <editor
                    :options="editorOptions"
                    height="400px"
                    initialEditType="wysiwyg"
                    previewStyle="vertical"
                    :initialValue="editorText"
                    v-model="contentEditor"
                    ref="toastuiEditor"
                  />
                  <ValidationProvider
                    name="내용"
                    rules="required"
                    ref="refContent"
                  >
                    <input type="text" v-model="content" class="hidden" />
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <th rowspan="3">첨부파일</th>
                <td class="file_find">
                  <input type="text" v-model="fileName01" />
                  <input
                    type="file"
                    id="selectFile01"
                    accept=".xls*, .ppt*, .doc*, .png, .jpg, .jpeg .pdf, .bmp, .gif"
                    ref="refSelectFile01"
                    @change="getFile01"
                  />
                  <label for="selectFile01" class="input_file">
                    파일찾기
                  </label>
                  <div
                    class="file_delete"
                    v-if="this.seq && this.item.attachOne"
                  >
                    {{ this.item.attachOne.fileNm }}
                    <input type="checkbox" id="exsting_file01" />
                    <label for="exsting_file01">삭제</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="file_find">
                  <input type="text" v-model="fileName02" />
                  <input
                    type="file"
                    id="selectFile02"
                    accept=".xls*, .ppt*, .doc*, .png, .jpg, .jpeg .pdf, .bmp, .gif"
                    ref="refSelectFile02"
                    @change="getFile02"
                  />
                  <label for="selectFile02" class="input_file">
                    파일찾기
                  </label>
                  <div
                    class="file_delete"
                    v-if="this.seq && this.item.attachTwo"
                  >
                    {{ this.item.attachTwo.fileNm }}
                    <input type="checkbox" id="exsting_file02" />
                    <label for="exsting_file02">삭제</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="file_find">
                  <input type="text" v-model="fileName03" />
                  <input
                    type="file"
                    id="selectFile03"
                    accept=".xls*, .ppt*, .doc*, .png, .jpg, .jpeg .pdf, .bmp, .gif"
                    ref="refSelectFile03"
                    @change="getFile03"
                  />
                  <label for="selectFile03" class="input_file">
                    파일찾기
                  </label>
                  <div
                    class="file_delete"
                    v-if="this.seq && this.item.attachThree"
                  >
                    {{ this.item.attachThree.fileNm }}
                    <input type="checkbox" id="exsting_file03" />
                    <label for="exsting_file03">삭제</label>
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>
        <div class="notice_write_button right_btn">
          <button type="button" @click="list">목록</button>
          <button v-if="seq != undefined" type="button" @click.once="update()">
            수정하기
          </button>
          <button v-else type="submit" @click.once="formSubmit">
            등록하기
          </button>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import { Editor } from "@toast-ui/vue-editor";
import "tui-color-picker/dist/tui-color-picker.css";
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  components: {
    editor: Editor,
  },
  props: {
    seq: {
      type: [String, Number],
      default: () => null,
    },
  },
  data() {
    return {
      contentLoading: true,

      editorOptions: {
        hideModeSwitch: true,
        language: "ko",
      },

      item: null,
      editorText: "",
      title: "",
      contentEditor: "",
      contentEditorText: "",
      search_category: "N",
      contentSearch: "",
      content: "",
      fileName01: "",
      fileName02: "",
      fileName03: "",
      selectFile01: "",
      selectFile02: "",
      selectFile03: "",
      attachCdOne: "",
      attachCdTwo: "",
      attachCdThree: "",
      files: ["", "", ""],
    };
  },
  methods: {
    list() {
      this.$router.push({
        name: "Notice",
      });
    },
    async formSubmit() {
      let isValidation = await this.validationCheck();
      if (isValidation) {
        this.create();
      }
    },
    async validationCheck() {
      this.content = this.$refs.toastuiEditor.invoke("getHtml");
      this.content = this.content.replace(/(<([^>]+)>)/gi, "");
      const refTitle = await this.$refs.refTitle.validate(),
        refContent = await this.$refs.refContent.validate();
      if (!refTitle.valid) {
        alert(refTitle.errors[0]);
        document.getElementById("title").focus();
        return false;
      }
      if (!refContent.valid) {
        alert(refContent.errors[0]);
        return false;
      }
      return true;
    },
    async read() {
      this.contentLoading = true;

      if (this.seq) {
        await client.boardNoticeView(this.seq).then(
          (response) => {
            const { data } = response;
            this.item = data;
            this.files = [
              data.attachCdOne,
              data.attachCdTwo,
              data.attachCdThree,
            ];
          },
          (error) => {
            console.log(error);
          }
        );
      }

      await setTimeout(() => {
        this.contentLoading = false;
      }, 500);

      if (this.item) {
        setTimeout(() => {
          this.getData();
        }, 500);
      }
    },
    getFile01(e) {
      if (0 < this.$refs.refSelectFile01.files.length) {
        this.selectFile01 = this.$refs.refSelectFile01.files[0];
        let fileExt = this.selectFile01.name.substring(
          this.selectFile01.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          [
            "doc",
            "docx",
            "pdf",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
          ].includes(fileExt)
        ) {
          const file = e.target.files[0];
          this.fileName01 = file.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile01 = null;
        }
      }
    },
    getFile02(e) {
      if (0 < this.$refs.refSelectFile02.files.length) {
        this.selectFile02 = this.$refs.refSelectFile02.files[0];
        let fileExt = this.selectFile02.name.substring(
          this.selectFile02.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          [
            "doc",
            "docx",
            "pdf",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
          ].includes(fileExt)
        ) {
          const file = e.target.files[0];
          this.fileName02 = file.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile02 = null;
        }
      }
    },
    getFile03(e) {
      if (0 < this.$refs.refSelectFile03.files.length) {
        this.selectFile03 = this.$refs.refSelectFile03.files[0];
        let fileExt = this.selectFile03.name.substring(
          this.selectFile03.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          [
            "doc",
            "docx",
            "pdf",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
          ].includes(fileExt)
        ) {
          const file = e.target.files[0];
          this.fileName03 = file.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile03 = null;
        }
      }
    },
    async create() {
      if (this.selectFile01 || this.selectFile02 || this.selectFile03) {
        let form = new FormData();
        if (this.selectFile01) {
          form.append("attach", this.selectFile01);
        }
        if (this.selectFile02) {
          form.append("attach", this.selectFile02);
        }
        if (this.selectFile03) {
          form.append("attach", this.selectFile03);
        }
        await client.attachUpload(form).then(
          (response) => {
            const { data } = response;
            for (let i = 0; i < data.length; i++) {
              this.files[i] = data[i].attachCd;
            }
            this.attachCdOne = this.files[0];
            this.attachCdTwo = this.files[1];
            this.attachCdThree = this.files[2];
          },
          (error) => {
            console.log(error);
          }
        );
      }

      this.contentEditor = this.$refs.toastuiEditor.invoke("getHtml");
      this.contentSearch = this.contentEditor.replace(/(<([^>]+)>)/gi, "");
      this.contentSearch = this.contentSearch.replace(/\n/gi, "");

      let params = {
        topYn: this.search_category,
        title: this.title,
        contentEditor: this.contentEditor,
        contentSearch: this.contentSearch,
        attachCdOne: this.attachCdOne,
        attachCdTwo: this.attachCdTwo,
        attachCdThree: this.attachCdThree,
      };

      // this.waitRequest = true;
      await client.boardNoticeReg(params).then(
        (response) => {
          // this.waitRequest = false;
          console.log(response);
          this.$router.push({
            name: "Notice",
          });
          alert("공지사항이 등록되었습니다.");
        },
        (error) => {
          // this.waitRequest = false;
          console.log(error);
        }
      );
    },
    async update() {
      let validation = await this.validationCheck();
      if (validation) {
        if (this.selectFile01 || this.selectFile02 || this.selectFile03) {
          let form = new FormData();
          if (this.selectFile01) {
            form.append("attach", this.selectFile01);
            this.files[0] = "";
          }
          if (this.selectFile02) {
            form.append("attach", this.selectFile02);
            this.files[1] = "";
          }
          if (this.selectFile03) {
            form.append("attach", this.selectFile03);
            this.files[2] = "";
          }

          await client.attachUpload(form).then(
            (response) => {
              const { data } = response;
              for (let i = 0; i < data.length; i++) {
                if (this.selectFile01 && this.files[0] == "") {
                  this.files[0] = data[i].attachCd;
                } else if (this.selectFile02 && this.files[1] == "") {
                  this.files[1] = data[i].attachCd;
                } else if (this.selectFile03 && this.files[2] == "") {
                  this.files[2] = data[i].attachCd;
                }
              }
              this.attachCdOne = this.files[0];
              this.attachCdTwo = this.files[1];
              this.attachCdThree = this.files[2];
            },
            (error) => {
              console.log(error);
            }
          );
        }
        this.contentEditor = this.$refs.toastuiEditor.invoke("getHtml");
        this.contentSearch = this.contentEditor.replace(/(<([^>]+)>)/gi, "");
        this.contentSearch = this.contentSearch.replace(
          /(\r\n\t|\n|\r\t)/gm,
          ""
        );

        let params = {
          topYn: this.search_category,
          title: this.title,
          contentEditor: this.contentEditor,
          contentSearch: this.contentSearch,
          attachCdOne: this.attachCdOne,
          attachCdTwo: this.attachCdTwo,
          attachCdThree: this.attachCdThree,
        };

        // this.waitRequest = true;
        await client.boardNoticeMod(this.seq, params).then(
          (response) => {
            // this.waitRequest = false;
            alert("수정되었습니다.");
            console.log(response);
            this.$router.push({
              name: "NoticeSeq",
              params: {
                seq: this.seq,
              },
            });
          },
          (error) => {
            // this.waitRequest = false;
            console.log(error);
          }
        );
      }
    },
    getData() {
      this.title = this.seq !== undefined ? this.item.title : "";
      this.editorText = this.seq !== undefined ? this.item.contentEditor : "";
      this.content = this.seq !== undefined ? this.item.contentSearch : "";
      if (this.seq !== undefined) {
        this.editorText = this.$refs.toastuiEditor.invoke(
          "setHtml",
          this.editorText
        );
      } else {
        this.editorText = "";
      }
      // this.fileName01 =
      //   this.seq !== undefined && this.item.attachOne
      //     ? this.item.attachOne.fileNm
      //     : "";
      // this.fileName02 =
      //   this.seq !== undefined && this.item.attachTwo
      //     ? this.item.attachTwo.fileNm
      //     : "";
      // this.fileName03 =
      //   this.seq !== undefined && this.item.attachThree
      //     ? this.item.attachThree.fileNm
      //     : "";
    },
  },
  created() {
    this.$Progress.start();

    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
