const search_category = [
  {
    key: "all",
    label: "전체",
    value: "",
  },
  {
    key: "Y",
    label: "공지",
    value: "Y",
  },
  {
    key: "N",
    label: "일반",
    value: "N",
  },
];

const search_condition = [
  {
    key: "all",
    label: "전체",
    value: "",
  },
  {
    key: "title",
    label: "제목",
    value: "title",
  },
  {
    key: "content",
    label: "내용",
    value: "content",
  },
];

export { search_category, search_condition };
