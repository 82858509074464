import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 프로젝트 전체 데이터 수
const projectTotalCount = (params) => {
  return http.get(SERVICE_API_URL + `/project/totalcount`, {
    params: params,
    headers: authHeader(),
  });
};

// 프로젝트 목록
const projectList = (params) => {
  return http.get(SERVICE_API_URL + `/project`, {
    params: params,
    headers: authHeader(),
  });
};

// 프로젝트 등록
const projectReg = (params) => {
  return http.post(SERVICE_API_URL + `/project`, params, {
    headers: authHeader(),
  });
};

// 프로젝트 수정
const projectMod = (seq, params) => {
  return http.put(SERVICE_API_URL + `/project/${seq}`, params, {
    headers: authHeader(),
  });
};

// 프로젝트 정보
const projectView = (seq) => {
  return http.get(SERVICE_API_URL + `/project/${seq}`, {
    headers: authHeader(),
  });
};

// 이전 프로젝트
const projectPrev = (seq) => {
  return http.get(SERVICE_API_URL + `/project/${seq}/prev`, {
    headers: authHeader(),
  });
};

// 다음 프로젝트
const projectNext = (seq) => {
  return http.get(SERVICE_API_URL + `/project/${seq}/next`, {
    headers: authHeader(),
  });
};

// 프로젝트 삭제
const projectDel = (seq) => {
  return http.delete(SERVICE_API_URL + `/project/${seq}`, {
    headers: authHeader(),
  });
};

export default {
  projectTotalCount,
  projectList,
  projectReg,
  projectMod,
  projectView,
  projectPrev,
  projectNext,
  projectDel,
};
