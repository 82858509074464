<template>
  <div class="login_body">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  components: {},
  data() {
    return {};
  },
};
</script>

<style></style>
