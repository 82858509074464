<template>
  <main>
    <div class="container">
      <div class="table_wrap">
        <table class="project_search_table htable">
          <tr>
            <th>기본 구분</th>
            <td>
              <div class="flex_wrap">
                <span>
                  <input
                    type="radio"
                    name="radio"
                    id="project_max_price"
                    value="project_max_price"
                    v-model="order_by_price"
                  />
                  <label for="project_max_price"> 금액 높은 순 </label>
                </span>
                <span>
                  <input
                    type="radio"
                    name="radio"
                    id="project_min_price"
                    value="project_min_price"
                    v-model="order_by_price"
                  />
                  <label for="project_min_price">금액 낮은 순</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    id="reg_dt_tm"
                    value="reg_dt_tm"
                    v-model="order_by_dt"
                  />
                  <label for="reg_dt_tm">최신 등록 순</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    id="request_expiration_dt"
                    value="request_expiration_dt"
                    v-model="order_by_dt"
                  />
                  <label for="request_expiration_dt">마감 임박 순</label>
                </span>
              </div>
            </td>
            <th>진행 방식</th>
            <td>
              <div class="flex_wrap">
                <input
                  type="checkbox"
                  @click="selectAll"
                  v-model="progressAllSelected"
                  id="progress_all"
                />
                <label for="progress_all">전체</label>
                <span v-for="progress in progresses" :key="progress.id">
                  <input
                    type="checkbox"
                    v-model="search_progress"
                    @click="select"
                    :value="progress.value"
                    :id="progress.id"
                  />
                  <label :for="progress.id">{{ progress.name }}</label>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <th>모집 구분</th>
            <td>
              <div class="flex_wrap">
                <input
                  type="checkbox"
                  @click="rselectAll"
                  v-model="recruitAllSelected"
                  id="recruitment_all"
                />
                <label for="recruitment_all">전체</label>

                <span v-for="recruitment in recruitments" :key="recruitment.id">
                  <input
                    type="checkbox"
                    v-model="search_recruit"
                    @click="rSelect"
                    :value="recruitment.value"
                    :id="recruitment.id"
                  />
                  <label :for="recruitment.id">
                    {{ recruitment.name }}
                  </label>
                </span>
              </div>
            </td>
            <th>업무 분야</th>
            <td>
              <div class="flex_wrap">
                <input
                  type="checkbox"
                  @click="fselectAll"
                  v-model="workAllSelected"
                  id="field_all"
                />
                <label for="field_all">전체</label>
                <span v-for="(work, index) in works" :key="index">
                  <input
                    type="checkbox"
                    v-model="search_work"
                    @click="fSelect"
                    :value="work.cd"
                    :id="work.cd"
                  />
                  <label :for="work.cd">{{ work.nm }}</label>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <th>세부 분야</th>
            <td colspan="3">
              <div class="flex_wrap">
                <input
                  type="checkbox"
                  @click="dselectAll"
                  v-model="taskAllSelected"
                  id="dfield_all"
                />
                <label for="dfield_all">전체</label>
                <span v-for="(task, index) in tasks" :key="index">
                  <input
                    type="checkbox"
                    v-model="search_task"
                    @click="dSelect"
                    :value="task.cd"
                    :id="task.cd"
                  />
                  <label :for="task.cd">{{ task.nm }}</label>
                </span>
              </div>
            </td>
          </tr>
          <tr class="area_section">
            <th>지역 구분</th>
            <td colspan="3">
              <div class="flex_wrap">
                <input
                  type="checkbox"
                  @click="aselectAll"
                  v-model="areaAllSelected"
                  id="area_all"
                />
                <label for="area_all">전체</label>
                <span v-for="(area, index) in areas" :key="index">
                  <input
                    type="checkbox"
                    v-model="search_area"
                    @click="aSelect"
                    :value="area.cd"
                    :id="area.cd"
                  />
                  <label :for="area.cd">{{ area.nm }}</label>
                </span>
              </div>
            </td>
          </tr>
          <tr class="project_price_date">
            <th>금액</th>
            <td>
              <template>
                <vue-numeric
                  class="price"
                  placeholder="월 단가"
                  currency=""
                  separator=","
                  v-model="search_start_price"
                ></vue-numeric>
              </template>
              이상 ~
              <template>
                <vue-numeric
                  class="price"
                  placeholder="월 단가"
                  currency=""
                  separator=","
                  v-model="search_end_price"
                ></vue-numeric>
              </template>
              이하
            </td>
            <th class="text_center">기간</th>
            <td colspan="3">
              <div class="date_picker">
                <date-picker
                  v-model="start_date"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input type="text" :value="inputValue" v-on="inputEvents" />
                  </template>
                </date-picker>
                ~
                <date-picker
                  v-model="end_date"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input type="text" :value="inputValue" v-on="inputEvents" />
                  </template>
                </date-picker>
              </div>
            </td>
          </tr>
          <tr class="input_width">
            <th>프로젝트 명</th>
            <td>
              <input type="text" v-model="search_project" />
            </td>
            <th>발주사</th>
            <td colspan="3">
              <input type="text" v-model="search_company" />
            </td>
          </tr>
        </table>
        <div class="center_btn">
          <button type="button" @click="formSubmit">검색</button>
        </div>
      </div>
      <div class="project_result table_wrap">
        <p class="total">
          검색 : <span>{{ totalElements }}</span
          >명 | 전체 : <span>{{ totalCount }}</span
          >명
        </p>
        <table class="project_result_table vtable">
          <thead>
            <tr>
              <th>번호</th>
              <th>진행 방식</th>
              <th>모집 구분</th>
              <th>업무 분야</th>
              <th>프로젝트 명</th>
              <th>발주사</th>
              <th>등록일</th>
              <th>지원자</th>
              <th>지원 결과</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="contentLoading">
              <td colspan="100">
                <clip-loader
                  class="spinner"
                  :loading="contentLoading"
                  :color="'#2da1dd'"
                  :size="'30px'"
                />
              </td>
            </tr>
            <tr v-else-if="!contentLoading && projects.length == 0">
              <td colspan="100">검색 결과가 없습니다.</td>
            </tr>
            <tr v-else v-for="(project, index) in projects" :key="index">
              <td>
                {{ totalElements - pageSize * (currentPage - 1) - index }}
              </td>
              <td v-if="project.progress == 1">상주(파견) 희망</td>
              <td v-if="project.progress == 2">도급 희망</td>
              <td v-if="project.progress == 0">기타</td>
              <td v-if="project.recruit == 1">신규</td>
              <td v-if="project.recruit == 2">모집 중</td>
              <td v-if="project.recruit == 3">모집 완료</td>
              <td v-if="project.work">{{ project.work.nm }}</td>
              <td @click="project_detail(project.projectCd)" class="cursor">
                {{ project.projectNm }}
              </td>
              <td class="cursor" @click="companyDetail(project)">
                {{ project.orderingCompany }}
              </td>
              <td>{{ project.regDtTm.slice(0, 10) }}</td>
              <td class="cursor" @click="openApplicant(project.projectCd)">
                {{ project.applyCount }}
              </td>
              <td class="cursor" @click="openSelection(project.projectCd)">
                {{ project.selectionCount > 0 ? "선정" : "-" }}
              </td>
            </tr>
          </tbody>
        </table>
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="totalPages ? totalPages : 1"
          use-router
          v-model="currentPage"
          :limit="10"
        ></b-pagination-nav>
        <div class="right_btn">
          <button type="button" @click="create()">신규 등록</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SelectionInfo from "@/components/SelectionInfo.vue";
import ApplicantInfo from "@/components/ApplicantInfo.vue";
import CompanyInfo from "@/components/CompanyInfo.vue";
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      contentLoading: true,

      order_by_dt: [],
      progresses: [
        { id: 1, value: 1, name: "상주(파견)희망" },
        { id: 2, value: 2, name: "도급 희망" },
        { id: 3, value: 0, name: "기타" },
      ],
      progressAllSelected: false,
      search_progress: [],
      recruitments: [
        { id: 4, value: 1, name: "신규 " },
        { id: 5, value: 2, name: "모집 중" },
        { id: 6, value: 3, name: "모집 완료" },
      ],
      recruitAllSelected: false,
      search_recruit: [],

      workAllSelected: false,
      search_work: [],
      works: [],
      // 세부분야
      taskAllSelected: false,
      search_task: [],
      tasks: [],
      // 지역구분
      areaAllSelected: false,
      search_area: [],
      areas: [],
      start_date: this.$date().subtract(1, "month").format("YYYY-MM-DD"),
      end_date: new Date(),
      projects: [],
      project: {},
      totalPages: 1,
      pageSize: 1,
      totalElements: 0,
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      order_by_price: "",
      search_start_price: "",
      search_end_price: "",
      search_start_dt: "",
      search_end_dt: "",
      search_project: "",
      search_company: "",
      projectCd: "",
      applyProjectCd: "",
      applyEa: "",
      totalCount: 0,
    };
  },
  created() {
    this.$Progress.start();

    if (this.$route.query) {
      this.order_by_price = this.$route.query.order_by_price
        ? this.$route.query.order_by_price
        : "project_max_price";
      this.order_by_dt = this.$route.query.order_by_dt
        ? this.$route.query.order_by_dt.split(",")
        : [];
      this.search_progress = this.$route.query.search_progress
        ? this.$route.query.search_progress.split(",")
        : [];
      this.search_recruit = this.$route.query.search_recruit
        ? this.$route.query.search_recruit.split(",")
        : [];
      this.search_work = this.$route.query.search_work
        ? this.$route.query.search_work.split(",")
        : [];
      this.search_task = this.$route.query.search_task
        ? this.$route.query.search_task.split(",")
        : [];
      this.search_area = this.$route.query.search_area
        ? this.$route.query.search_area.split(",")
        : [];
      this.search_start_price = this.$route.query.search_start_price
        ? this.$route.query.search_start_price
        : "";
      this.search_end_price = this.$route.query.search_end_price
        ? this.$route.query.search_end_price
        : "";
      this.start_date = this.$route.query.search_start_dt
        ? this.$route.query.search_start_dt
        : this.start_date;
      this.end_date = this.$route.query.search_end_dt
        ? this.$route.query.search_end_dt
        : this.end_date;
      this.search_project = this.$route.query.search_project
        ? this.$route.query.search_project
        : "";
      this.search_company = this.$route.query.search_company
        ? this.$route.query.search_company
        : "";
    }
    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    formSubmit() {
      let params = this.getFormData();
      params.page = 1;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      if (
        "/project?" + encodeURI(payloadString) !=
        this.$router.history.current.fullPath
      ) {
        this.$router.push({
          name: "Project",
          query: params,
        });
      }
    },
    async read() {
      this.contentLoading = true;

      await client.codeList("WORK").then(
        (response) => {
          const { data } = response;
          this.works = data;
        },
        (error) => {
          console.log(error);
        }
      );
      await client.codeList("TASK").then(
        (response) => {
          const { data } = response;
          this.tasks = data;
        },
        (error) => {
          console.log(error);
        }
      );
      await client.codeList("AREA").then(
        (response) => {
          const { data } = response;
          this.areas = data;
        },
        (error) => {
          console.log(error);
        }
      );

      let params = this.getFormData();
      await client.projectList(params).then(
        (response) => {
          const { data } = response;
          this.projects = data.content;
          this.totalPages = data.totalPages;
          this.pageSize = data.size;
          this.totalElements = data.totalElements;
        },
        (error) => {
          console.log(error);
        }
      );
      await client.projectTotalCount(params).then(
        (response) => {
          const { data } = response;
          this.totalCount = data.totalCount;
        },
        (error) => {
          console.log(error);
        }
      );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    getFormData() {
      let params = {};
      if (this.order_by_price != null && this.order_by_price != "") {
        params.order_by_price = this.order_by_price;
      }
      if (this.order_by_dt != null && this.order_by_dt != "") {
        if (Array.isArray(this.order_by_dt)) {
          params.order_by_dt = this.order_by_dt.toString();
        } else {
          params.order_by_dt = this.order_by_dt;
        }
      }
      if (this.search_progress != null && this.search_progress != "") {
        if (Array.isArray(this.search_progress)) {
          params.search_progress = this.search_progress.toString();
        } else {
          params.search_progress = this.search_progress;
        }
      }
      if (this.search_recruit != null && this.search_recruit != "") {
        if (Array.isArray(this.search_recruit)) {
          params.search_recruit = this.search_recruit.toString();
        } else {
          params.search_recruit = this.search_recruit;
        }
      }
      if (this.search_work != null && this.search_work != "") {
        if (Array.isArray(this.search_work)) {
          params.search_work = this.search_work.toString();
        } else {
          params.search_work = this.search_work;
        }
      }
      if (this.search_task != null && this.search_task != "") {
        if (Array.isArray(this.search_task)) {
          params.search_task = this.search_task.toString();
        } else {
          params.search_task = this.search_task;
        }
      }
      if (this.search_area != null && this.search_area != "") {
        if (Array.isArray(this.search_area)) {
          params.search_area = this.search_area.toString();
        } else {
          params.search_area = this.search_area;
        }
      }
      if (this.search_start_price.length != 0) {
        params.search_start_price = this.search_start_price;
      }
      if (this.search_end_price.length != 0) {
        params.search_end_price = this.search_end_price;
      }
      if (this.start_date) {
        params.search_start_dt = this.$date(this.start_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.end_date) {
        params.search_end_dt = this.$date(this.end_date).format("YYYY-MM-DD");
      }

      if (this.search_project.length != 0) {
        params.search_project = this.search_project;
      }
      if (this.search_company.length != 0) {
        params.search_company = this.search_company;
      }
      params.page = this.$route.query.page;

      return params;
    },
    linkGen(pageNum) {
      let params = this.getFormData();
      params.page = pageNum;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      return `?${payloadString}`;
    },
    companyDetail(project) {
      this.$modal.show(
        CompanyInfo,
        {
          project: project,
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    openSelection(projectCd) {
      this.$modal.show(
        SelectionInfo,
        {
          projectCd: projectCd,
        },
        {
          width: 1300,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    openApplicant(applyProjectCd) {
      this.$modal.show(
        ApplicantInfo,
        {
          applyProjectCd: applyProjectCd,
        },
        {
          width: 1300,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    create() {
      this.$router.push({
        name: "ProjectEdit",
      });
    },
    project_detail(projectCd) {
      this.$router.push({
        name: "ProjectSeq",
        params: {
          seq: projectCd,
        },
      });
    },
    // 진행방식
    selectAll() {
      this.search_progress = [];

      if (!this.progressAllSelected) {
        for (this.progress in this.progresses) {
          this.search_progress.push(
            this.progresses[this.progress].value.toString()
          );
        }
      }
    },
    select() {
      this.progressAllSelected = false;
    },
    // 모집구분
    rselectAll: function () {
      this.search_recruit = [];

      if (!this.recruitAllSelected) {
        for (this.recruitment in this.recruitments) {
          this.search_recruit.push(
            this.recruitments[this.recruitment].value.toString()
          );
        }
      }
    },
    rSelect() {
      this.recruitAllSelected = false;
    },
    // 업무분야
    fselectAll() {
      this.search_work = [];

      if (!this.workAllSelected) {
        for (this.work in this.works) {
          this.search_work.push(this.works[this.work].cd.toString());
        }
      }
    },
    fSelect() {
      this.workAllSelected = false;
    },
    // 세부분야
    dselectAll() {
      this.search_task = [];

      if (!this.taskAllSelected) {
        for (this.task in this.tasks) {
          this.search_task.push(this.tasks[this.task].cd.toString());
          console.log(this.search_task);
        }
      }
    },
    dSelect() {
      this.taskAllSelected = false;
    },
    // 지역구분
    aselectAll() {
      this.search_area = [];

      if (!this.areaAllSelected) {
        for (this.area in this.areas) {
          this.search_area.push(this.areas[this.area].cd.toString());
        }
      }
    },
    aSelect: function () {
      this.areaAllSelected = false;
    },
  },
};
</script>
