<template>
  <main>
    <div class="container">
      <div class="table_wrap">
        <form @submit.prevent="formSubmit">
          <table class="notice_search_table htable">
            <tr>
              <th><label for="search_category">구분</label></th>
              <td>
                <select
                  name="search_category"
                  id="search_category"
                  v-model="form.search_category"
                >
                  <option
                    v-for="(item, index) in constants.search_category"
                    :value="item.value"
                    :key="`search_category_${index}`"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </td>
              <th>작성일</th>
              <td>
                <date-picker v-model="start" :popover="{ visibility: 'click' }">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      name="search_start_dt"
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </date-picker>
                <date-picker v-model="end" :popover="{ visibility: 'click' }">
                  <template v-slot="{ inputValue, inputEvents }">
                    ~
                    <input
                      name="search_end_dt"
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </date-picker>
              </td>
            </tr>
            <tr>
              <th>검색어</th>
              <td colspan="3">
                <div
                  class="search_keyword_input"
                  v-for="(item, index) in constants.search_condition"
                  :key="`search_condition_${index}`"
                >
                  <input
                    type="radio"
                    name="search_condition"
                    :id="`radio${item.key}`"
                    v-model="form.search_condition"
                    :value="item.value"
                  /><label :for="`radio${item.key}`">{{ item.label }}</label>
                </div>
                <input
                  type="text"
                  name="search_keyword"
                  v-model="form.search_keyword"
                />
              </td>
            </tr>
          </table>
          <div class="center_btn">
            <button type="button" @click="formSubmit">검색</button>
          </div>
        </form>
      </div>
      <div class="notice_result table_wrap">
        <p class="total">
          검색 : <span>{{ totalElements }}</span
          >명 | 전체 : <span>{{ totalCount }}</span
          >명
        </p>
        <div>
          <table class="notice_result_table vtable">
            <thead>
              <tr>
                <th
                  v-for="(item, index) in fields"
                  :key="`th_${index}`"
                  :width="item.width ? item.width : ''"
                >
                  {{ item.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="contentLoading">
                <td colspan="100">
                  <clip-loader
                    class="spinner"
                    :loading="contentLoading"
                    :color="'#2da1dd'"
                    :size="'30px'"
                  />
                </td>
              </tr>
              <tr v-else-if="!contentLoading && items.length == 0">
                <td colspan="100">검색 결과가 없습니다.</td>
              </tr>
              <tr
                v-else
                v-for="(item, index) in items"
                :key="index"
                @click.prevent.stop="link('NoticeSeq', { seq: item.seq })"
              >
                <td v-for="(field, index) in fields" :key="`td_${index}`">
                  <template v-if="field.key == 'topYn'">
                    {{ item[field.key] == "Y" ? "공지" : "일반" }}
                  </template>
                  <template v-else>
                    {{ item[field.key] }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="totalPages ? totalPages : 1"
          use-router
          v-model="currentPage"
          :limit="pageSize"
        ></b-pagination-nav>
        <div class="right_btn">
          <button type="button" @click.prevent.stop="link('NoticeEdit')">
            신규등록
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { search_category, search_condition } from "@/constants/notice";
import client from "api-client";

export default {
  name: "Notice",
  data() {
    return {
      contentLoading: true,

      constants: {
        search_category,
        search_condition,
      },

      form: {
        search_category: "",
        search_condition: "",
        search_keyword: "",
      },
      start: this.$date().subtract(7, "day").format("YYYY-MM-DD"),
      end: this.$date().format("YYYY-MM-DD"),

      fields: [
        {
          key: "no",
          width: "100px",
          label: "번호",
        },
        {
          key: "topYn",
          width: "100px",
          label: "구분",
        },

        {
          key: "title",
          width: "60%",
          label: "제목",
        },

        {
          key: "regDtTm",
          width: "20%",
          label: "작성 일시",
        },
      ],
      items: [],
      currentPage: 1,
      totalPages: 1,
      pageSize: 20,
      totalElements: 0,
      totalCount: 0,
    };
  },
  created() {
    this.$Progress.start();

    Object.keys(this.$route.query).forEach((queryKey) => {
      Object.keys(this.form).forEach((formKey) => {
        if (formKey == queryKey) {
          this.form[formKey] = this.$route.query[queryKey];
        }
      });

      if (queryKey == "search_start_dt") {
        this.start = this.$route.query[queryKey];
      }
      if (queryKey == "search_end_dt") {
        this.end = this.$route.query[queryKey];
      }

      if (queryKey == "page") {
        this.currentPage = this.$route.query.page;
      }
    });

    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    formSubmit() {
      let params = this.getFormData();
      if (params.page) {
        delete params.page;
      }

      this.$router.push({
        name: "Notice",
        query: params,
      });
    },
    getFormData() {
      let params = {};
      Object.keys(this.form).forEach((formKey) => {
        this.form[formKey] = this.form[formKey] ? this.form[formKey] : "";
        if (0 < this.form[formKey].replace(/ /g, "").length) {
          params[formKey] = this.form[formKey];
        }
      });

      if (this.start) {
        Object.assign(params, {
          search_start_dt: this.$date(this.start).format("YYYY-MM-DD"),
        });
      }

      if (this.end) {
        Object.assign(params, {
          search_end_dt: this.$date(this.end).format("YYYY-MM-DD"),
        });
      }

      if (this.currentPage) {
        Object.assign(params, { page: this.currentPage });
      }

      return params;
    },
    linkGen(pageNum) {
      let params = this.getFormData();
      params.page = pageNum;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      return `?${payloadString}`;
    },
    link(name = "", params = null) {
      if (name) {
        this.$router.push({
          name: name,
          params: params,
        });
      }
    },
    async read() {
      this.contentLoading = true;

      let params = this.getFormData();
      await client.boardNoticeList(params).then(
        (response) => {
          const { data } = response;
          this.totalPages = data.totalPages;
          this.pageSize = data.size;
          this.totalElements = data.totalElements;
          data.content.forEach((item, index) => {
            item.no =
              this.totalElements -
              this.pageSize * (this.currentPage - 1) -
              index;
          });
          this.items = data.content;
        },
        (error) => {
          console.log(error);
        }
      );

      await client
        .boardNoticeTotalCount(params)
        .then((response) => {
          const { data } = response;
          this.totalCount = data.totalCount;
        })
        .catch((error) => {
          console.log(error);
        });

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
  },
};
</script>
