var CryptoJS = require("crypto-js");

export const Crypto = {
  /**
   * salt=594919A2BFC968F0
   * key=E58C7F1FDF2DDFCBC3BDBB1AD87B7BC4
   * iv =E2A22DAC7737C68C0A79DC5124023934
   */
  cryptoAesEncrypt(value) {
    var aesKey = CryptoJS.enc.Utf8.parse("E58C7F1FDF2DDFCBC3BDBB1AD87B7BC4");
    var iv = CryptoJS.enc.Utf8.parse("E2A22DAC7737C68C0A79DC5124023934");
    var encrypted = CryptoJS.AES.encrypt(String(value), aesKey, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }).toString();
    return encrypted;
  },
  cryptoAesDecrypt(value) {
    var aesKey = CryptoJS.enc.Utf8.parse("E58C7F1FDF2DDFCBC3BDBB1AD87B7BC4");
    var iv = CryptoJS.enc.Utf8.parse("E2A22DAC7737C68C0A79DC5124023934");
    var encrypted = CryptoJS.AES.decrypt(String(value), aesKey, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }).toString(CryptoJS.enc.Utf8);
    return encrypted;
  },
};

export default Crypto;
