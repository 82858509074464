<template>
  <div class="applicant_choose">
    <div class="applicant_contact_content popup_content">
      <h3>지원자 선정</h3>
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <div class="popup_table_wrap">
        <table class="applicant_contact_table htable">
          <tr>
            <th>업무 분야</th>
            <td>
              <select v-model="selectionWorkCd" required>
                <option value="" disabled selected>업무 분야</option>
                <option v-for="work in works" :key="work.id" :value="work.cd">
                  {{ work.nm }}
                </option>
              </select>
              <ValidationProvider
                name="업무 분야"
                rules="required"
                ref="refWork"
              >
                <input type="text" :value="selectionWorkCd" class="hidden" />
              </ValidationProvider>
            </td>
            <th>등급</th>
            <td>
              <select v-model="selectionRank" required>
                <option value="" disabled selected>등급</option>
                <option value="S">특급</option>
                <option value="A">고급</option>
                <option value="B">중급</option>
                <option value="C">초급</option>
              </select>
              <ValidationProvider name="등급" rules="required" ref="refRank">
                <input type="text" :value="selectionRank" class="hidden" />
              </ValidationProvider>
            </td>
          </tr>
          <tr>
            <th>투입 일자</th>
            <td>
              <date-picker
                v-model="selectionStartDt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <ValidationProvider
                    name="투입 일자"
                    rules="required"
                    ref="refStartDt"
                  >
                    <input
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                      ref="refStartDtInput"
                    />
                  </ValidationProvider>
                </template>
              </date-picker>
            </td>
            <th>철수 일자</th>
            <td>
              <date-picker
                v-model="selectionEndDt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <ValidationProvider
                    name="철수 일자"
                    rules="required"
                    ref="refEndDt"
                  >
                    <input
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                      ref="refEndDtInput"
                    />
                  </ValidationProvider>
                </template>
              </date-picker>
            </td>
          </tr>
          <tr>
            <th>월 단가</th>
            <td>
              <ValidationProvider
                name="월 단가"
                rules="numeric|required"
                ref="refPrice"
              >
                <input
                  type="number"
                  class="price_input"
                  v-model="selectionPrice"
                  ref="refPriceInput"
                />원
              </ValidationProvider>
            </td>
            <th>지급일</th>
            <td>
              <ValidationProvider name="지급일" rules="required" ref="refPayDt">
                <input
                  type="text"
                  v-model="selectionPayDt"
                  ref="refPayDtInput"
                />
              </ValidationProvider>
            </td>
          </tr>
          <tr>
            <th>기타</th>
            <td colspan="3">
              <textarea id="aplicant_etc" v-model="selectionContent"></textarea>
            </td>
          </tr>
        </table>
      </div>
      <div class="center_btn">
        <button type="button" @click="formSubmit">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/common/http";

export default {
  props: {
    seq: Number,
    project_cd: String,
  },
  data() {
    return {
      url: "/api/project/apply/",
      work_url: "/api/code/search/WORK",
      works: [],
      selectionWorkCd: "",
      selectionRank: "",
      selectionStartDt: this.$date().format("YYYY-MM-DD"),
      selectionEndDt: this.$date().format("YYYY-MM-DD"),
      selectionPrice: "",
      selectionPayDt: "",
      selectionContent: "",
    };
  },
  created() {
    this.read();
  },
  methods: {
    async formSubmit() {
      let isValidation = await this.validation();
      if (isValidation) {
        this.create();
      }
    },
    async create() {
      const params = {
        selectionWorkCd: this.selectionWorkCd,
        selectionRank: this.selectionRank,
        selectionStartDt: this.$date(this.selectionStartDt).format(
          "YYYY-MM-DD"
        ),
        selectionEndDt: this.$date(this.selectionEndDt).format("YYYY-MM-DD"),
        selectionPrice: this.selectionPrice,
        selectionPayDt: this.selectionPayDt,
        selectionContent: this.selectionContent,
      };
      await http
        .post(`${this.url}${this.project_cd}/selection/${this.seq}`, params)
        .then(() => {
          alert("선정처리 되었습니다.");
          this.close();
          this.$router.go();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async validation() {
      const refWork = await this.$refs.refWork.validate(),
        refRank = await this.$refs.refRank.validate(),
        refStartDt = await this.$refs.refStartDt.validate(),
        refEndDt = await this.$refs.refEndDt.validate(),
        refPrice = await this.$refs.refPrice.validate(),
        refPayDt = await this.$refs.refPayDt.validate();
      let startDt = new Date(this.selectionStartDt);
      let EndDt = new Date(this.selectionEndDt);

      if (!refWork.valid) {
        alert(refWork.errors[0]);
        return false;
      }
      if (!refRank.valid) {
        alert(refRank.errors[0]);
        return false;
      }
      if (!refStartDt.valid) {
        alert(refStartDt.errors[0]);
        return false;
      }
      if (!refEndDt.valid) {
        alert(refEndDt.errors[0]);
        return false;
      }

      if (!refPrice.valid) {
        alert(refPrice.errors[0]);
        this.$refs.refPriceInput.focus();
        return false;
      }
      if (!refPayDt.valid) {
        alert(refPayDt.errors[0]);
        this.$refs.refPayDtInput.focus();
        return false;
      }

      if (startDt > EndDt) {
        alert("철수 일자는 투입 일자보다 빠를 수 없습니다.");
        return false;
      }
      return true;
    },
    read() {
      http
        .get(this.work_url)
        .then((response) => {
          const { data } = response;
          this.works = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
