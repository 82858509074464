import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 메일링 목록
const mailingPaging = (params) => {
  return http.get(SERVICE_API_URL + `/mailing`, {
    params: params,
    headers: authHeader(),
  });
};

// 메일링 등록
const mailingEdit = (params) => {
  return http.post(SERVICE_API_URL + `/mailing`, params, {
    headers: authHeader(),
  });
};

// 메일링 삭제
const mailingDel = (seq) => {
  return http.delete(SERVICE_API_URL + `/mailing/${seq}`, {
    headers: authHeader(),
  });
};

export default {
  mailingPaging,
  mailingEdit,
  mailingDel,
};
