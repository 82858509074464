<template>
  <main>
    <div class="container">
      <div class="table_wrap">
        <table class="persnal_qna_search_table htable">
          <tr>
            <th>구분</th>
            <td>
              <select
                name="qnaDivision"
                id="qnaDivision"
                v-model="search_category"
                required
              >
                <option value="" disabled selected>질문 구분</option>
                <option v-for="qna in qnas" :key="qna.id" :value="qna.cd">
                  {{ qna.nm }}
                </option>
              </select>
            </td>
            <th>작성일</th>
            <td>
              <date-picker
                v-model="search_start_dt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" :value="inputValue" v-on="inputEvents" />
                </template>
              </date-picker>
              ~
              <date-picker
                v-model="search_end_dt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" :value="inputValue" v-on="inputEvents" />
                </template>
              </date-picker>
            </td>
            <th>답변 유무</th>
            <td>
              <select name="answer" id="answer" v-model="search_qna">
                <option value="">전체</option>
                <option value="N">답변 전</option>
                <option value="Y">답변 완료</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>검색어</th>
            <td colspan="5">
              <input
                type="radio"
                name="myradio"
                checked
                value=""
                v-model="search_condition"
                id="search_condition_all"
              />
              <label for="search_condition_all">전체</label>
              <input
                type="radio"
                name="myradio"
                value="question_content"
                v-model="search_condition"
                id="search_condition_content"
              />
              <label for="search_condition_content">제목</label>
              <input
                type="radio"
                name="myradio"
                value="question_nm"
                v-model="search_condition"
                id="search_condition_nm"
              />
              <label for="search_condition_nm">작성자</label>
              <input type="text" v-model="search_keyword" />
            </td>
          </tr>
        </table>
        <div class="center_btn">
          <button type="button" @click="formSubmit">검색</button>
        </div>
      </div>
      <div class="persnal_qna_result table_wrap">
        <p class="total">
          검색 : <span>{{ totalElements }}</span
          >명 | 전체 : <span>{{ totalCount }}</span
          >명
        </p>
        <table class="persnal_qna_result_table vtable">
          <thead>
            <tr>
              <th>번호</th>
              <th>구분</th>
              <th class="qna_head_content">내용</th>
              <th>작성자(아이디)</th>
              <th>작성 일시</th>
              <th>답변 유무</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="contentLoading">
              <td colspan="100">
                <clip-loader
                  class="spinner"
                  :loading="contentLoading"
                  :color="'#2da1dd'"
                  :size="'30px'"
                />
              </td>
            </tr>
            <tr v-else-if="!contentLoading && items.length == 0">
              <td colspan="100">등록된 문의가 없습니다.</td>
            </tr>
            <tr v-else v-for="(item, index) in items" :key="index">
              <td>
                {{ totalElements - pageSize * (currentPage - 1) - index }}
              </td>
              <td v-if="item.category">{{ item.category.nm }}</td>
              <td
                class="qna_list_content"
                @click="goDetail(item.seq, item.answerContent)"
              >
                {{ item.questionContent }}
              </td>
              <td class="cursor" @click="detailMember(item.questionId)">
                {{ item.questionId }}
              </td>
              <td>{{ item.questionDtTm }}</td>
              <td v-if="item.answerContent">답변 완료</td>
              <td v-else>답변 전</td>
            </tr>
          </tbody>
        </table>
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="totalPages ? totalPages : 1"
          use-router
          v-model="currentPage"
          :limit="10"
        ></b-pagination-nav>
      </div>
    </div>
  </main>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      contentLoading: true,
      qna_url: "/api/code/search/QNA",
      qnas: [],
      url: "/api/board/qna",
      items: [],
      search_category: "",
      search_start_dt: this.$date().subtract(7, "days").format("YYYY-MM-DD"),
      search_end_dt: this.$date().format("YYYY-MM-DD"),
      search_qna: "",
      search_condition: "",
      search_keyword: "",
      totalPages: 1,
      pageSize: 1,
      totalElements: 0,
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      totalCount: 0,
    };
  },
  created() {
    this.$Progress.start();

    if (this.$route.query) {
      this.search_category = this.$route.query.search_category
        ? this.$route.query.search_category
        : "";
      this.search_start_dt = this.$route.query.search_start_dt
        ? this.$route.query.search_start_dt
        : this.search_start_dt;
      this.search_end_dt = this.$route.query.search_end_dt
        ? this.$route.query.search_end_dt
        : this.search_end_dt;
      this.search_qna = this.$route.query.search_qna
        ? this.$route.query.search_qna
        : "";
      this.search_condition = this.$route.query.search_condition
        ? this.$route.query.search_condition
        : "";
      this.search_keyword = this.$route.query.search_keyword
        ? this.$route.query.search_keyword
        : "";
    }
    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    formSubmit() {
      let params = this.getFormData();
      params.page = 1;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      if (
        "/qna?" + encodeURI(payloadString) !=
        this.$router.history.current.fullPath
      ) {
        this.$router.push({
          name: "Qna",
          query: params,
        });
      }
    },
    getFormData() {
      let params = {};
      if (this.search_category != null && this.search_category != "") {
        params.search_category = this.search_category;
      }
      if (this.search_start_dt) {
        params.search_start_dt = this.$date(this.search_start_dt).format(
          "YYYY-MM-DD"
        );
      }
      if (this.search_end_dt) {
        params.search_end_dt = this.$date(this.search_end_dt).format(
          "YYYY-MM-DD"
        );
      }
      if (this.search_qna != null && this.search_qna.length != "") {
        params.search_qna = this.search_qna;
      }
      if (this.search_condition != null && this.search_condition != "") {
        params.search_condition = this.search_condition;
      }
      if (this.search_keyword.length != 0) {
        params.search_keyword = this.search_keyword;
      }
      params.page = this.$route.query.page;

      return params;
    },
    linkGen(pageNum) {
      let params = this.getFormData();
      params.page = pageNum;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      return `?${payloadString}`;
    },
    async read() {
      this.contentLoading = true;

      let params = this.getFormData();

      await client.boardQnaList(params).then(
        (response) => {
          const { data } = response;
          this.items = data.content;
          this.totalPages = data.totalPages;
          this.pageSize = data.size;
          this.totalElements = data.totalElements;
        },
        (error) => {
          console.log(error);
        }
      );

      await client.codeList("QNA").then(
        (response) => {
          const { data } = response;
          this.qnas = data;
        },
        (error) => {
          console.log(error);
        }
      );

      await client.boardQnaTotalCount(params).then(
        (response) => {
          const { data } = response;
          this.totalCount = data.totalCount;
        },
        (error) => {
          console.log(error);
        }
      );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    detailMember(questionId) {
      this.$router.push({
        name: "MemberSeq",
        params: {
          seq: questionId,
        },
      });
    },
    goDetail(seq, answerContent) {
      if (answerContent) {
        this.$router.push({
          name: "QnaSeq",
          params: {
            seq: seq,
          },
        });
      } else {
        this.$router.push({
          name: "QnaEditSeq",
          params: {
            seq: seq,
          },
        });
      }
    },
  },
};
</script>
