import attachService from "./attach.service";
import boardContactService from "./board.contact.service";
import boardNoticeService from "./board.notice.service";
import boardQnaService from "./board.qna.service";
import codeService from "./code.service";
import mailingService from "./mailing.service";
import memberService from "./member.service";
import projectApplyService from "./project.apply.service";
import projectJobService from "./project.job.service";
import projectService from "./project.service";
import authService from "./auth.service";

export default {
  ...attachService,
  ...boardContactService,
  ...boardNoticeService,
  ...boardQnaService,
  ...codeService,
  ...mailingService,
  ...memberService,
  ...projectApplyService,
  ...projectJobService,
  ...projectService,
  // authService
  authLogin(params) {
    return authService.login(params);
  },
  authLogout() {
    return authService.logout();
  },
  authUser() {
    return authService.user();
  },
};
