import Vue from "vue";
import Vuex from "vuex";
import services from "@/services";
import Jwt from "@/common/jwt";
import Storage from "@/common/storage";
import { ID_MEMBER } from "@/constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: {
      accessToken: Jwt.getAccessToken(),
    }, // 토큰정보
    member: Storage.get(ID_MEMBER),
    isAuthenticated: !!Jwt.getAccessToken(),
  },
  mutations: {
    login: function (state, payload = {}) {
      state.token.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      Jwt.saveAccessToken(payload.accessToken);
    },
    logout: function (state) {
      state.token.accessToken = "";
      state.isAuthenticated = false;
      Jwt.destroyAccessToken();
      Storage.remove(ID_MEMBER);
    },
    member: function (state, payload = {}) {
      state.member = payload.member;
      Storage.save(ID_MEMBER, payload.member);
    },
  },
  getters: {
    getAccessToken: function (state) {
      return state.token.accessToken;
    },
    isAuthenticated: function (state) {
      return state.isAuthenticated;
    },
    getMember: function (state) {
      return state.member;
    },
  },
  actions: {
    logout: function (context) {
      return new Promise((resolve, reject) => {
        //  로그인 API
        services.authLogout().then(
          (response) => {
            if (response.status == 200) {
              context.commit("logout");
            }
            resolve(response);
          },
          (error) => {
            // status 404
            if (error.response) {
              reject(error.response);
            } else {
              reject({
                data: {
                  message: "다시 시도해주세요.",
                },
              });
            }
          }
        );
      });
    },
    login: function (context, payload) {
      const params = new FormData();
      params.append("member_id", payload.member_id);
      params.append("member_pw", payload.member_pw);

      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);
        console.log(params);

        //  로그인 API
        services.authLogin(params).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              context.commit("login", {
                accessToken: data,
                member_id: payload.member_id,
              });
            }
            resolve(response);
          },
          (error) => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
    member: function (context, payload) {
      return new Promise((resolve, reject) => {
        services.memberAdminView(payload.member_id).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              context.commit("member", { member: data });
            }
            resolve(response);
          },
          (error) => {
            if (error.response) {
              reject(error.response);
            } else {
              reject({
                data: {
                  message: "다시 시도해주세요.",
                },
              });
            }
          }
        );
      });
    },
  },
  modules: {},
});
