<template>
  <main>
    <div class="container">
      <div class="table_wrap">
        <table class="member_search_table htable">
          <colgroup>
            <col width="50px" />
            <col width="300px" />
            <col width="100px" />
            <col width="300px" />
            <col width="50px" />
            <col width="300px" />
          </colgroup>
          <tr>
            <th scope="row">경력</th>
            <td>
              <input type="number" v-model="search_min_career" /> 년 ~
              <input type="number" v-model="search_max_career" /> 년
            </td>
            <th scope="row">월 단가</th>
            <td>
              <input type="number" v-model="search_min_price" />
              원 ~ <input type="number" v-model="search_max_price" /> 원
            </td>
          </tr>
          <tr>
            <th scope="row">가입 일시</th>
            <td>
              <date-picker
                v-model="search_start_dt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" :value="inputValue" v-on="inputEvents" />
                </template>
              </date-picker>
              ~
              <date-picker
                v-model="search_end_dt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" :value="inputValue" v-on="inputEvents" />
                </template>
              </date-picker>
            </td>
            <th scope="row">프로젝트 진행 유무</th>
            <td>
              <input
                type="radio"
                name="pradio"
                id="pro_all"
                v-model="search_project_yn"
                value=""
                checked
              />
              <label for="pro_all">전체</label>
              <input
                type="radio"
                name="pradio"
                id="proceeding"
                v-model="search_project_yn"
                value="Y"
              /><label for="proceeding">진행중</label>
              <input
                type="radio"
                name="pradio"
                id="no_proceeding"
                v-model="search_project_yn"
                value="N"
              /><label for="no_proceeding">진행하지 않음</label>
            </td>
            <th>진행 수</th>
            <td>
              <select name="progress_num" v-model="search_project_cnt">
                <option value="" selected>- 선택 -</option>
                <option value="1">1개 이상</option>
                <option value="2">2개 이상</option>
                <option value="3">3개 이상</option>
                <option value="4">4개 이상</option>
                <option value="5">5개 이상</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>검색어</th>
            <td colspan="3">
              <input
                type="radio"
                name="sradio"
                value=""
                v-model="search_condition"
                id="search_all"
              />
              <label for="search_all">전체</label>
              <input
                type="radio"
                name="sradio"
                value="member_nm"
                v-model="search_condition"
                id="search_name"
              />
              <label for="search_name">이름</label>
              <input
                type="radio"
                name="sradio"
                value="member_id"
                v-model="search_condition"
                id="search_id"
              />
              <label for="search_id">아이디</label>
              <input
                type="text"
                class="search_input"
                placeholder="검색어를 입력해 주세요."
                v-model="search_keyword"
              />
            </td>
          </tr>
        </table>
        <div class="center_btn">
          <button type="button" @click="formSubmit">검색</button>
        </div>
      </div>
      <div class="member_results table_wrap">
        <p class="total">
          검색 : <span>{{ this.totalElements }}</span
          >명 | 전체 : <span>{{ this.totalCount }}</span
          >명
        </p>
        <!-- <tr v-if="contentLoading">
                <td colspan="100">
                  <clip-loader
                    class="spinner"
                    :loading="contentLoading"
                    :color="'#2da1dd'"
                    :size="'30px'"
                  />
                </td>
              </tr> -->
        <div id="comp_test">
          <base-table
            :loading="contentLoading"
            :totalItems="totalItems"
            :currentPage="currentPage"
            :useNo="true"
            :fields="tablefields"
            :items="items"
            @click="onTableClick"
          >
            <template v-slot:careerYear="slotProps">
              <span
                >{{ slotProps.item.careerYear }}년
                {{ slotProps.item.careerMonth }}개월
              </span>
            </template>
            <template v-slot:minPrice="slotProps">
              <span
                >{{ slotProps.item.minPrice | comma }} ~
                {{ slotProps.item.maxPrice | comma }}</span
              >
            </template>
            <template v-slot:attachCdResume="slotProps">
              <a
                class="member_resume"
                :href="`${fileDown_url}${slotProps.item.attachCdResume}`"
                target="_blank"
              >
                <i><font-awesome-icon :icon="['fas', 'file-alt']" /></i>
              </a>
            </template>
          </base-table>
        </div>
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="totalPages ? totalPages : 1"
          use-router
          v-model="currentPage"
          :limit="10"
        ></b-pagination-nav>
      </div>
    </div>
  </main>
</template>

<script>
import ProgressDetail from "@/components/ProgressDetail";
import ProjectEndDate from "@/components/ProjectEndDate.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  name: "Member",
  components: {
    BaseTable,
  },
  data() {
    return {
      contentLoading: true,

      tablefields: [
        {
          key: "careerYear",
          label: "경력",
          useSlot: true,
        },
        {
          key: "minPrice",
          label: "월 단가",
          useSlot: true,
        },
        {
          key: "attachCdResume",
          label: "기술서",
          useSlot: true,
        },
        {
          key: "memberNm",
          label: "이름",
          useClick: true,
        },
        {
          key: "memberId",
          label: "아이디",
          useClick: true,
        },
        {
          key: "mobile",
          label: "전화번호",
        },
        {
          key: "email",
          label: "이메일",
        },
        {
          key: "regDtTm",
          label: "가입일",
          formatter: {
            name: "date",
            type: "short",
          },
        },
        {
          key: "projectCount",
          label: "진행 수",
          useClick: true,
        },
        {
          key: "projectEnd",
          label: "프로젝트 종료 일정",
          useClick: true,
        },
      ],
      totalItems: 0,
      item: {},
      fields: [
        { id: 7, name: "PM/기획" },
        { id: 8, name: "디자인" },
        { id: 9, name: "퍼블리싱" },
        { id: 10, name: "개발" },
        { id: 11, name: "기타" },
      ],
      fallSelected: false,
      fselected: [],
      fieldIds: [],
      search_min_career: "",
      search_max_career: "",
      search_min_price: "",
      search_max_price: "",
      search_start_dt: this.$date().subtract(1, "month").format("YYYY-MM-DD"),
      search_end_dt: this.$date().format("YYYY-MM-DD"),
      search_condition: "",
      search_keyword: "",
      search_project_cnt: "",
      items: [],
      totalPages: "",
      pageSize: "",
      totalElements: "",
      totalCount: "",
      search_project_yn: "",
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      fileDown_url: process.env.VUE_APP_API_URL + "/api/attach/",
    };
  },
  created() {
    this.$Progress.start();

    if (this.$route.query) {
      this.search_min_career = this.$route.query.search_min_career
        ? this.$route.query.search_min_career
        : "";
      this.search_max_career = this.$route.query.search_max_career
        ? this.$route.query.search_max_career
        : "";
      this.search_project_yn = this.$route.query.search_project_yn
        ? this.$route.query.search_project_yn
        : "";
      this.search_min_price = this.$route.query.search_min_price
        ? this.$route.query.search_min_price
        : "";
      this.search_max_price = this.$route.query.search_max_price
        ? this.$route.query.search_max_price
        : "";
      this.search_start_dt = this.$route.query.search_start_dt
        ? this.$route.query.search_start_dt
        : this.search_start_dt;
      this.search_end_dt = this.$route.query.search_end_dt
        ? this.$route.query.search_end_dt
        : this.search_end_dt;
      this.search_condition = this.$route.query.search_condition
        ? this.$route.query.search_condition
        : "";
      this.search_keyword = this.$route.query.search_keyword
        ? this.$route.query.search_keyword
        : "";
      this.search_project_cnt = this.$route.query.search_project_cnt
        ? this.$route.query.search_project_cnt
        : "";
    }
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    onTableClick(params) {
      switch (params.field.key) {
        case "memberNm":
        case "memberId":
          this.detail(params.item.memberId);
          break;
        case "projectCount":
          this.onProgressDetail(params.item.memberId, params.item.projectCount);
          break;
        case "projectEnd":
          this.onProjectEndDate(params.item.memberId, params.item.projectEnd);
          break;
        default:
          return;
      }
    },
    onProgressDetail(memberId, projectCount) {
      if (projectCount > 0) {
        this.$modal.show(
          ProgressDetail,
          {
            memberId: memberId,
          },
          {
            width: 1300,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      }
    },
    onProjectEndDate(memberId, projectEnd) {
      if (projectEnd != null) {
        this.$modal.show(
          ProjectEndDate,
          {
            memberId: memberId,
          },
          {
            width: 1300,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      }
    },
    formSubmit() {
      let params = this.getFormData();
      params.page = 1;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      if (
        "/member?" + encodeURI(payloadString) !=
        this.$router.history.current.fullPath
      ) {
        this.$router.push({
          name: "Member",
          query: params,
        });
      }
    },
    async fetch() {
      this.contentLoading = true;

      let params = this.getFormData();

      await client.memberUserList(params).then(
        (response) => {
          const { data } = response;
          this.items = data.content;
          this.projectCount = data.content.projectCount;
          this.totalPages = data.totalPages;
          this.pageSize = data.size;
          this.totalElements = data.totalElements;
          this.totalItems = data.totalElements;
        },
        (error) => {
          console.log(error);
        }
      );

      await client.memberUserTotalCount().then(
        (response) => {
          const { data } = response;
          this.totalCount = data.totalCount;
        },
        (error) => {
          console.log(error);
        }
      );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    getFormData() {
      let params = {};
      if (this.search_min_career != null && this.search_min_career != "") {
        params.search_min_career = this.search_min_career;
      }
      if (this.search_max_career != null && this.search_max_career != "") {
        params.search_max_career = this.search_max_career;
      }
      if (this.search_min_price != null && this.search_min_price != "") {
        params.search_min_price = this.search_min_price;
      }
      if (this.search_max_price != null && this.search_max_price != "") {
        params.search_max_price = this.search_max_price;
      }
      if (this.search_start_dt) {
        params.search_start_dt = this.$date(this.search_start_dt).format(
          "YYYY-MM-DD"
        );
      }
      if (this.search_end_dt) {
        params.search_end_dt = this.$date(this.search_end_dt).format(
          "YYYY-MM-DD"
        );
      }
      if (this.search_project_cnt != null && this.search_project_cnt != "") {
        params.search_project_cnt = this.search_project_cnt;
      }
      if (this.search_project_yn != null && this.search_project_yn != "") {
        params.search_project_yn = this.search_project_yn;
      }
      if (this.search_condition != null && this.search_condition != "") {
        params.search_condition = this.search_condition;
      }
      if (this.search_keyword.length != 0) {
        params.search_keyword = this.search_keyword;
      }
      params.page = this.$route.query.page;
      return params;
    },
    linkGen(pageNum) {
      let params = this.getFormData();
      params.page = pageNum;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      return `?${payloadString}`;
    },
    detail(memberId) {
      this.$router.push({
        name: "MemberSeq",
        params: {
          seq: memberId,
        },
      });
    },
    fselectAll: function () {
      this.fieldIds = [];

      if (!this.fallSelected) {
        for (this.field in this.fields) {
          this.fieldIds.push(this.fields[this.field].id.toString());
        }
      }
    },
    fSelect: function () {
      this.fallSelected = false;
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
