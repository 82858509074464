<template>
  <main class="project_write">
    <div class="container">
      <template v-if="contentLoading">
        <clip-loader
          class="spinner"
          :loading="contentLoading"
          :color="'#2da1dd'"
          :size="'30px'"
        />
      </template>
      <template v-else>
        <div class="table_wrap">
          <table class="project_write_table htable">
            <tr>
              <th>프로젝트 명</th>
              <td colspan="3" class="project_write_title">
                <ValidationProvider
                  name="프로젝트 명"
                  rules="max:50|required"
                  ref="refProjectNm"
                >
                  <input
                    type="text"
                    v-model="project.projectNm"
                    ref="projectNmInput"
                  />
                </ValidationProvider>
              </td>
              <th>모집 종료일</th>
              <td>
                <date-picker
                  v-model="project.requestExpirationDt"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <ValidationProvider
                      name="모집 종료일"
                      rules="required"
                      ref="refExpirationDt"
                    >
                      <input
                        type="text"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </ValidationProvider>
                  </template>
                </date-picker>
              </td>
            </tr>
            <tr>
              <th>진행 방식</th>
              <td>
                <select v-model="project.progress" required>
                  <option value="" disabled selected>진행 방식</option>
                  <option value="1">상주(파견) 희망</option>
                  <option value="2">도급 희망</option>
                  <option value="0">기타</option>
                </select>
                <ValidationProvider
                  name="진행 방식"
                  rules="required"
                  ref="refProgress"
                >
                  <input type="text" :value="project.progress" class="hidden" />
                </ValidationProvider>
              </td>
              <th>업무 분야</th>
              <td>
                <select v-model="project.work" required>
                  <option value="" disabled selected>업무 분야</option>
                  <option v-for="work in works" :key="work.id" :value="work.cd">
                    {{ work.nm }}
                  </option>
                </select>
                <ValidationProvider
                  name="업무 분야"
                  rules="required"
                  ref="refWork"
                >
                  <input type="text" :value="project.work" class="hidden" />
                </ValidationProvider>
              </td>
              <th>세부 분야</th>
              <td>
                <select v-model="project.task" required>
                  <option value="" disabled selected>세부 분야</option>
                  <option v-for="task in tasks" :key="task.id" :value="task.cd">
                    {{ task.nm }}
                  </option>
                </select>
                <ValidationProvider
                  name="세부 분야"
                  rules="required"
                  ref="refTask"
                >
                  <input type="text" :value="project.task" class="hidden" />
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <th>지역 구분</th>
              <td width="10%">
                <select name="city" v-model="project.area" required>
                  <option value="" disabled selected>지역 구분</option>
                  <option v-for="area in areas" :key="area.id" :value="area.cd">
                    {{ area.nm }}
                  </option>
                </select>
                <ValidationProvider name="지역" rules="required" ref="refArea">
                  <input type="text" :value="project.area" class="hidden" />
                </ValidationProvider>
              </td>
              <th>모집 구분</th>
              <td>
                <select name="city" v-model="project.recruit" required>
                  <option value="" disabled selected>모집 구분</option>
                  <option value="1">신규</option>
                  <option value="2">모집 중</option>
                  <option value="3">모집 완료</option>
                </select>
                <ValidationProvider
                  name="모집 구분"
                  rules="required"
                  ref="refRecruit"
                >
                  <input type="text" :value="project.recruit" class="hidden" />
                </ValidationProvider>
              </td>
              <th>프로젝트 기간</th>
              <td class="project_write_term">
                <date-picker
                  v-model="project.projectStartDt"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <ValidationProvider
                      name="프로젝트 시작일"
                      rules="required"
                      ref="refPStartDt"
                    >
                      <input
                        type="text"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </ValidationProvider>
                  </template>
                </date-picker>
                ~
                <date-picker
                  v-model="project.projectEndDt"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <ValidationProvider
                      name="프로젝트 종료일"
                      rules="required"
                      ref="refPEndDt"
                    >
                      <input
                        type="text"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </ValidationProvider>
                  </template>
                </date-picker>
              </td>
            </tr>
            <tr>
              <th>프로젝트 금액</th>
              <td colspan="3">
                <ValidationProvider
                  name="최소 금액"
                  rules="numeric|required"
                  ref="refPMinPrice"
                >
                  <template>
                    <vue-numeric
                      class="price"
                      currency=""
                      separator=","
                      v-model="project.projectMinPrice"
                    ></vue-numeric>
                  </template>
                </ValidationProvider>
                원 ~
                <ValidationProvider
                  name="최대 금액"
                  rules="numeric|required"
                  ref="refPMaxPrice"
                >
                  <template>
                    <vue-numeric
                      class="price"
                      currency=""
                      separator=","
                      v-model="project.projectMaxPrice"
                    ></vue-numeric>
                  </template>
                </ValidationProvider>
                원
              </td>
              <th>현재 상태</th>
              <td colspan="5" class="project_write_state">
                <ValidationProvider
                  name="현재 상태"
                  rules="max:100|required"
                  ref="refState"
                >
                  <input type="text" v-model="project.state" ref="PState" />
                </ValidationProvider>
              </td>
            </tr>
            <tr class="wetc">
              <th>기타 내용</th>
              <td colspan="9">
                <textarea name="wetc" v-model="project.etcContent"></textarea>
              </td>
            </tr>
          </table>
        </div>
        <div class="table_wrap">
          <table class="job_part_table htable">
            <tr class="jop_part_rows">
              <th>발주사 명</th>
              <td>
                <ValidationProvider
                  name="발주사 명"
                  rules="max:50|required"
                  ref="refOrderingCompany"
                >
                  <input
                    type="text"
                    v-model="project.orderingCompany"
                    ref="OrderingCompany"
                  />
                </ValidationProvider>
              </td>
              <th>사업자 번호</th>
              <td>
                <ValidationProvider
                  name="사업자 번호"
                  rules="min:10|max:12|numeric|required"
                  ref="refBusinessLicense"
                >
                  <input
                    type="text"
                    v-model="project.businessLicense"
                    placeholder="“-”를 제외한 숫자만 입력"
                    ref="refBusinessLicenseInput"
                  />
                </ValidationProvider>
              </td>
              <th>대표번호</th>
              <td>
                <ValidationProvider
                  name="대표번호"
                  rules="max:20|numeric|required"
                  ref="refMainPhone"
                >
                  <input
                    type="text"
                    v-model="project.mainPhone"
                    placeholder="“-”를 제외한 숫자만 입력"
                    ref="refMainPhoneInput"
                  />
                </ValidationProvider>
              </td>
            </tr>
            <tr class="jop_part_rows">
              <th>담당자 명</th>
              <td>
                <ValidationProvider
                  name="담당자 명"
                  rules="max:50|required"
                  ref="refManagerNm"
                >
                  <input
                    type="text"
                    v-model="project.managerNm"
                    ref="refManagerNmInput"
                  />
                </ValidationProvider>
              </td>
              <th>연락처</th>
              <td>
                <ValidationProvider
                  name="연락처"
                  rules="max:20|numeric|required"
                  ref="refContactPhone"
                >
                  <input
                    type="text"
                    v-model="project.contactPhone"
                    placeholder="“-”를 제외한 숫자만 입력"
                    ref="refContactPhoneInput"
                  />
                </ValidationProvider>
              </td>
              <th>이메일</th>
              <td>
                <ValidationProvider
                  name="이메일"
                  rules="email|max:320|required"
                  ref="refEmail"
                >
                  <input
                    type="email"
                    v-model="project.email"
                    ref="refEmailInput"
                  />
                </ValidationProvider>
              </td>
            </tr>
            <tr class="jop_part_rows">
              <th>원 발주사</th>
              <td>
                <ValidationProvider
                  name="원 발주사"
                  rules="max:50|required"
                  ref="refCustomerCompany"
                >
                  <input
                    type="text"
                    v-model="project.customerCompany"
                    ref="refCustomerCompanyInput"
                  />
                </ValidationProvider>
              </td>
              <th>근무지</th>
              <td>
                <ValidationProvider
                  name="근무지"
                  rules="max:50|required"
                  ref="refPlace"
                >
                  <input
                    type="text"
                    v-model="project.place"
                    ref="refPlaceInput"
                  />
                </ValidationProvider>
              </td>
              <th>프로젝트 기간</th>
              <td class="job_part_term">
                <date-picker
                  v-model="project.requestStartDt"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <ValidationProvider
                      name="프로젝트 시작일"
                      rules="required"
                      ref="refRequestStartDt"
                    >
                      <input
                        type="text"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </ValidationProvider>
                  </template>
                </date-picker>
                ~
                <date-picker
                  v-model="project.requestEndDt"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <ValidationProvider
                      name="프로젝트 종료일"
                      rules="required"
                      ref="refRequestEndDt"
                    >
                      <input
                        type="text"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </ValidationProvider>
                  </template>
                </date-picker>
              </td>
            </tr>
            <tr>
              <th>구인 파트</th>
              <td colspan="5" class="job_part">
                <div
                  v-for="(job, index) in jobs.items"
                  :key="'job' + index"
                  class="job_part_row"
                >
                  <select v-model="job.jobWorkCd" required>
                    <option value="" disabled selected>업무 분야</option>
                    <option
                      v-for="work in works"
                      :key="work.id"
                      :value="work.cd"
                    >
                      {{ work.nm }}
                    </option>
                  </select>
                  <select v-model="job.jobRank" required>
                    <option value="" disabled selected>등급</option>
                    <option value="S">특급</option>
                    <option value="A">고급</option>
                    <option value="B">중급</option>
                    <option value="C">초급</option>
                  </select>
                  <template>
                    <vue-numeric
                      class="price"
                      placeholder="월 단가"
                      currency=""
                      separator=","
                      v-model="job.jobPrice"
                    ></vue-numeric>
                  </template>
                  <date-picker
                    v-model="job.jobStartDt"
                    :popover="{ visibility: 'click' }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        type="text"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </date-picker>
                  ~
                  <date-picker
                    v-model="job.jobEndDt"
                    :popover="{ visibility: 'click' }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        type="text"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </date-picker>
                </div>
              </td>
            </tr>
            <tr class="pwrite_detail">
              <th>상세내용</th>
              <td colspan="5">
                <textarea
                  name="pwriteDetail"
                  v-model="project.detailContent"
                ></textarea>
              </td>
            </tr>
          </table>
        </div>
        <div class="right_btn">
          <button type="button" @click="goList">목록</button>
          <button
            type="submit"
            @click.once="
              projectCd !== undefined ? updateSubmit() : formSubmit()
            "
          >
            {{ projectCd !== undefined ? "수정하기" : "등록하기" }}
          </button>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";
import VueNumeric from "vue-numeric";

export default {
  components: {
    VueNumeric,
  },
  data() {
    return {
      contentLoading: true,

      works: [],
      tasks: [],
      areas: [],
      projectCd: "",
      project: {
        projectNm: "",
        requestExpirationDt: this.$date().format("YYYY-MM-DD"),
        progress: "",
        work: "",
        task: "",
        area: "",
        recruit: "",
        projectStartDt: this.$date().format("YYYY-MM-DD"),
        projectEndDt: this.$date().format("YYYY-MM-DD"),
        projectMinPrice: "",
        projectMaxPrice: "",
        state: "",
        etcContent: "",
        orderingCompany: "",
        businessLicense: "",
        mainPhone: "",
        managerNm: "",
        contactPhone: "",
        email: "",
        customerCompany: "",
        place: "",
        requestStartDt: this.$date().format("YYYY-MM-DD"),
        requestEndDt: this.$date().format("YYYY-MM-DD"),
        detailContent: "",
      },
      jobs: {
        items: [],
        limit: 10,
        templete: {
          jobWorkCd: "",
          jobRank: "",
          jobPrice: "",
          jobStartDt: this.$date().format("YYYY-MM-DD"),
          jobEndDt: this.$date().format("YYYY-MM-DD"),
        },
      },
      getprojectCd: "",
      updateProject: {},
      updateJob: [],
      price: "",
    };
  },
  watch: {
    "project.businessLicense"() {
      this.project.businessLicense = this.project.businessLicense.replace(
        /[^0-9]/g,
        ""
      );
    },
    "project.mainPhone"() {
      this.project.mainPhone = this.project.mainPhone.replace(/[^0-9]/g, "");
    },
    "project.contactPhone"() {
      this.project.contactPhone = this.project.contactPhone.replace(
        /[^0-9]/g,
        ""
      );
    },
  },
  methods: {
    async formSubmit() {
      let validation = await this.validation();
      if (validation) {
        const response = await this.create();
        if (response.status == 200) {
          await this.createJobPart();
          this.$router.push({
            name: "Project",
          });
        }
      }
    },
    async create() {
      return new Promise((resolve, reject) => {
        let params = {
          projectNm: this.project.projectNm,
          requestExpirationDt: this.$date(
            this.project.requestExpirationDt
          ).format("YYYY-MM-DD"),
          progress: this.project.progress,
          workCd: this.project.work,
          taskCd: this.project.task,
          areaCd: this.project.area,
          recruit: this.project.recruit,
          projectStartDt: this.$date(this.project.projectStartDt).format(
            "YYYY-MM-DD"
          ),
          projectEndDt: this.$date(this.project.projectEndDt).format(
            "YYYY-MM-DD"
          ),
          projectMinPrice: this.project.projectMinPrice,
          projectMaxPrice: this.project.projectMaxPrice,
          state: this.project.state,
          etcContent: this.project.etcContent,
          orderingCompany: this.project.orderingCompany,
          businessLicense: this.project.businessLicense,
          mainPhone: this.project.mainPhone,
          managerNm: this.project.managerNm,
          contactPhone: this.project.contactPhone,
          email: this.project.email,
          customerCompany: this.project.customerCompany,
          place: this.project.place,
          requestStartDt: this.$date(this.project.requestStartDt).format(
            "YYYY-MM-DD"
          ),
          requestEndDt: this.$date(this.project.requestEndDt).format(
            "YYYY-MM-DD"
          ),
          detailContent: this.project.detailContent,
        };

        // this.waitRequest = true;
        client.projectReg(params).then(
          (response) => {
            // this.waitRequest = false;
            const { data } = response;
            this.getprojectCd = data.projectCd;
            resolve(response);
            alert("프로젝트가 등록되었습니다.");
          },
          (error) => {
            // this.waitRequest = false;
            reject(error);
          }
        );
      });
    },

    async createJobPart() {
      const jobs = [];
      this.jobs.items.forEach((job) => {
        if (
          job.jobWorkCd.length != 0 &&
          job.jobRank.length != 0 &&
          job.jobPrice.length != 0
        ) {
          jobs.push(job);
        }
      });

      await client.projectJobEdit(this.getprojectCd, jobs).then(
        (response) => {
          const { data } = response;
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
    },

    async updateSubmit() {
      let validation = await this.validation();
      if (validation) {
        const response = await this.update();
        if (response.status == 200) {
          await this.updateJobPart();
          this.$router.push({
            name: "ProjectSeq",
            params: {
              seq: this.getprojectCd,
            },
          });
        }
      }
    },
    async update() {
      return new Promise((resolve, reject) => {
        let params = {
          projectNm: this.project.projectNm,
          requestExpirationDt: this.$date(
            this.project.requestExpirationDt
          ).format("YYYY-MM-DD"),
          progress: this.project.progress,
          workCd: this.project.work,
          taskCd: this.project.task,
          areaCd: this.project.area,
          recruit: this.project.recruit,
          projectStartDt: this.$date(this.project.projectStartDt).format(
            "YYYY-MM-DD"
          ),
          projectEndDt: this.$date(this.project.projectEndDt).format(
            "YYYY-MM-DD"
          ),
          projectMinPrice: this.project.projectMinPrice,
          projectMaxPrice: this.project.projectMaxPrice,
          state: this.project.state,
          etcContent: this.project.etcContent,
          orderingCompany: this.project.orderingCompany,
          businessLicense: this.project.businessLicense,
          mainPhone: this.project.mainPhone,
          managerNm: this.project.managerNm,
          contactPhone: this.project.contactPhone,
          email: this.project.email,
          customerCompany: this.project.customerCompany,
          place: this.project.place,
          requestStartDt: this.$date(this.project.requestStartDt).format(
            "YYYY-MM-DD"
          ),
          requestEndDt: this.$date(this.project.requestEndDt).format(
            "YYYY-MM-DD"
          ),
          detailContent: this.project.detailContent,
        };

        // this.waitRequest = true;
        client.projectMod(this.projectCd, params).then(
          (response) => {
            // this.waitRequest = false;
            const { data } = response;
            this.getprojectCd = data.projectCd;
            resolve(response);
            alert("프로젝트가 수정되었습니다.");
          },
          (error) => {
            // this.waitRequest = false;
            console.log(error);
            reject(error);
          }
        );
      });
    },

    async updateJobPart() {
      const jobs = [];
      this.jobs.items.forEach((job) => {
        if (
          job.jobWorkCd.length != 0 &&
          job.jobRank.length != 0 &&
          job.jobPrice.length != 0
        ) {
          jobs.push(job);
        }
      });

      await client.projectJobEdit(this.getprojectCd, jobs).then(
        (response) => {
          const { data } = response;
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async validation() {
      const refProjectNm = await this.$refs.refProjectNm.validate(),
        refExpirationDt = await this.$refs.refProjectNm.validate(),
        refProgress = await this.$refs.refProgress.validate(),
        refWork = await this.$refs.refWork.validate(),
        refTask = await this.$refs.refTask.validate(),
        refArea = await this.$refs.refArea.validate(),
        refRecruit = await this.$refs.refRecruit.validate(),
        refPStartDt = await this.$refs.refPStartDt.validate(),
        refPEndDt = await this.$refs.refPEndDt.validate(),
        refPMinPrice = await this.$refs.refPMinPrice.validate(),
        refPMaxPrice = await this.$refs.refPMaxPrice.validate(),
        refState = await this.$refs.refState.validate(),
        refOrderingCompany = await this.$refs.refOrderingCompany.validate(),
        refBusinessLicense = await this.$refs.refBusinessLicense.validate(),
        refMainPhone = await this.$refs.refMainPhone.validate(),
        refManagerNm = await this.$refs.refManagerNm.validate(),
        refContactPhone = await this.$refs.refContactPhone.validate(),
        refEmail = await this.$refs.refEmail.validate(),
        refCustomerCompany = await this.$refs.refCustomerCompany.validate(),
        refPlace = await this.$refs.refPlace.validate(),
        refRequestStartDt = await this.$refs.refRequestStartDt.validate(),
        refRequestEndDt = await this.$refs.refRequestEndDt.validate();
      let checkProjectStart = new Date(this.project.projectStartDt);
      let checkProjectEnd = new Date(this.project.projectEndDt);
      let checkRequestStart = new Date(this.project.requestStartDt);
      let checkRequestEnd = new Date(this.project.requestEndDt);

      if (!refProjectNm.valid) {
        alert(refProjectNm.errors[0]);
        this.$refs.projectNmInput.focus();
        return false;
      }
      if (!refExpirationDt.valid) {
        alert(refExpirationDt.errors[0]);
        return false;
      }
      if (!refProgress.valid) {
        alert(refProgress.errors[0]);
        return false;
      }
      if (!refWork.valid) {
        alert(refWork.errors[0]);
        return false;
      }
      if (!refTask.valid) {
        alert(refTask.errors[0]);
        return false;
      }
      if (!refArea.valid) {
        alert(refArea.errors[0]);
        return false;
      }
      if (!refRecruit.valid) {
        alert(refRecruit.errors[0]);
        return false;
      }
      if (!refPStartDt.valid) {
        alert(refPStartDt.errors[0]);
        return false;
      }
      if (!refPEndDt.valid) {
        alert(refPEndDt.errors[0]);
        return false;
      }
      if (checkProjectStart > checkProjectEnd) {
        alert("프로젝트 종료일은 시작일 보다 빠를 수 없습니다.");
        return false;
      }
      if (!refPMinPrice.valid) {
        alert(refPMinPrice.errors[0]);
        return false;
      }
      if (!refPMaxPrice.valid) {
        alert(refPMaxPrice.errors[0]);
        return false;
      }
      if (!refState.valid) {
        alert(refState.errors[0]);
        this.$refs.PState.focus();
        return false;
      }
      if (!refOrderingCompany.valid) {
        alert(refOrderingCompany.errors[0]);
        this.$refs.OrderingCompany.focus();
        return false;
      }

      if (!refBusinessLicense.valid) {
        alert(refBusinessLicense.errors[0]);
        this.$refs.refBusinessLicenseInput.focus();
        return false;
      }
      if (!refMainPhone.valid) {
        alert(refMainPhone.errors[0]);
        this.$refs.refMainPhoneInput.focus();
        return false;
      }
      if (!refManagerNm.valid) {
        alert(refManagerNm.errors[0]);
        this.$refs.refManagerNmInput.focus();
        return false;
      }
      if (!refContactPhone.valid) {
        alert(refContactPhone.errors[0]);
        this.$refs.refContactPhoneInput.focus();
        return false;
      }
      if (!refEmail.valid) {
        alert(refEmail.errors[0]);
        this.$refs.refEmailInput.focus();
        return false;
      }
      if (!refCustomerCompany.valid) {
        alert(refCustomerCompany.errors[0]);
        this.$refs.refCustomerCompanyInput.focus();
        return false;
      }
      if (!refPlace.valid) {
        alert(refPlace.errors[0]);
        this.$refs.refPlaceInput.focus();
        return false;
      }
      if (!refRequestStartDt.valid) {
        alert(refRequestStartDt.errors[0]);
        return false;
      }
      if (!refRequestEndDt.valid) {
        alert(refRequestEndDt.errors[0]);
        return false;
      }
      if (checkRequestStart > checkRequestEnd) {
        alert("프로젝트 종료일은 시작일 보다 빠를 수 없습니다.");
        return false;
      }
      return true;
    },
    goList() {
      this.$router.push({
        name: "Project",
      });
    },
    async read() {
      this.contentLoading = true;

      await client.codeList("WORK").then(
        (response) => {
          const { data } = response;
          this.works = data;
        },
        (error) => {
          console.log(error);
        }
      );
      await client.codeList("TASK").then(
        (response) => {
          const { data } = response;
          this.tasks = data;
        },
        (error) => {
          console.log(error);
        }
      );
      await client.codeList("AREA").then(
        (response) => {
          const { data } = response;
          this.areas = data;
        },
        (error) => {
          console.log(error);
        }
      );

      this.projectCd = this.$route.params.seq;
      if (this.projectCd !== undefined) {
        await client.projectView(this.projectCd).then(
          (response) => {
            const { data } = response;
            this.updateProject = data;
            this.getProjectData();
          },
          (error) => {
            console.log(error);
          }
        );

        await client.projectJobList(this.projectCd).then(
          (response) => {
            const { data } = response;
            this.updateJob = data;
            this.getJobData();
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.getJobData();
      }

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    getProjectData() {
      if (this.projectCd !== undefined) {
        this.project.projectNm = this.updateProject.projectNm;
        this.project.requestExpirationDt = this.updateProject.requestExpirationDt;
        this.project.progress = this.updateProject.progress;
        this.project.work = this.updateProject.workCd;
        this.project.task = this.updateProject.taskCd;
        this.project.area = this.updateProject.areaCd;
        this.project.recruit = this.updateProject.recruit;
        this.project.projectStartDt = this.updateProject.projectStartDt;
        this.project.projectEndDt = this.updateProject.projectEndDt;
        this.project.projectMinPrice = this.updateProject.projectMinPrice;
        this.project.projectMaxPrice = this.updateProject.projectMaxPrice;
        this.project.state = this.updateProject.state;
        this.project.etcContent = this.updateProject.etcContent;
        this.project.orderingCompany = this.updateProject.orderingCompany;
        this.project.businessLicense = this.updateProject.businessLicense;
        this.project.mainPhone = this.updateProject.mainPhone;
        this.project.managerNm = this.updateProject.managerNm;
        this.project.contactPhone = this.updateProject.contactPhone;
        this.project.email = this.updateProject.email;
        this.project.customerCompany = this.updateProject.customerCompany;
        this.project.place = this.updateProject.place;
        this.project.requestStartDt = this.updateProject.requestStartDt;
        this.project.requestEndDt = this.updateProject.requestEndDt;
        this.project.detailContent = this.updateProject.detailContent;
      } else {
        this.project.projectNm = "";
        this.project.requestExpirationDt = "";
        this.project.progress = "";
        this.project.work = "";
        this.project.task = "";
        this.project.area = "";
        this.project.recruit = "";
        this.project.projectStartDt = "";
        this.project.projectEndDt = "";
        this.project.projectMinPrice = "";
        this.project.projectMaxPrice = "";
        this.project.state = "";
        this.project.etcContent = "";
        this.project.orderingCompany = "";
        this.project.businessLicense = "";
        this.project.mainPhone = "";
        this.project.managerNm = "";
        this.project.contactPhone = "";
        this.project.email = "";
        this.project.customerCompany = "";
        this.project.place = "";
        this.project.requestStartDt = "";
        this.project.requestEndDt = "";
        this.project.detailContent = "";
      }
    },
    getJobData() {
      this.updateJob.forEach((job) => {
        this.jobs.items.push(job);
      });
      console.log("this.updateJob");
      console.log(this.updateJob);
      let jobRow = true;
      while (jobRow) {
        if (this.jobs.items.length < this.jobs.limit) {
          this.jobs.items.push(Object.assign({}, this.jobs.templete));
        } else {
          jobRow = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  created() {
    this.$Progress.start();

    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
};
</script>
