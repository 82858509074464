import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import VueDayjs from "vue-dayjs-plugin";
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/i18n/ko-kr";
// i18n
import i18n from "./i18n";
import { ValidationProvider, extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import VueProgressBar from "vue-progressbar";

Vue.use(VueDayjs);
Vue.component("date-picker", DatePicker);
Vue.use(BootstrapVue);
Vue.component("ValidationProvider", ValidationProvider);
Vue.config.productionTip = false;

for (let rule in rules) {
  extend(rule, rules[rule]);
}

extend("required", {
  message: "{_field_}을(를) 입력해 주세요.",
});

import ko from "vee-validate/dist/locale/ko.json";
localize({
  ko,
});
localize("ko");

// vue-spinner
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
Vue.component("clip-loader", ClipLoader);

// vue-progressbar
const options = {
  color: "#2da1dd",
  failedColor: "#d62923",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(VueProgressBar, options);

// vue-js-modal
import VModal from "vue-js-modal";
Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });

// vue-numeric
import VueNumeric from "vue-numeric";

Vue.use(VueNumeric);

// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faSortDown,
  faSortUp,
  faSort,
  faEdit,
  faTrashAlt,
  faFileAlt,
  faUser,
  faNetworkWired,
  faListCheck,
  faEnvelope,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faSortDown,
  faSortUp,
  faSort,
  faEdit,
  faTrashAlt,
  faFileAlt,
  faUser,
  faNetworkWired,
  faListCheck,
  faEnvelope,
  faCircleExclamation
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
