<template>
  <div class="company info">
    <div class="company_info_content popup_content">
      <h3>발주사 정보</h3>
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <div class="basic_info">
        <p>
          <strong>발주사 명</strong> :
          <span>{{ project.orderingCompany }}</span>
        </p>
        <p>
          <strong>사업자번호</strong> :
          <span>{{ project.businessLicense }}</span>
        </p>
        <p>
          <strong>대표번호</strong> : <span>{{ project.mainPhone }}</span>
        </p>
        <p></p>
        <p>
          <strong>담당자 명</strong> : <span>{{ project.managerNm }}</span>
        </p>
        <p>
          <strong>담당자 연락처</strong> :
          <span>{{ project.contactPhone }}</span>
        </p>
        <p>
          <strong>담당자 이메일</strong> : <span>{{ project.email }}</span>
        </p>
      </div>
      <div class="center_btn">
        <button type="button" @click="close">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
    },
    projectCd: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
