<template>
  <div class="applicant_info">
    <div class="applicant_info_content popup_content">
      <h3>지원자 정보</h3>
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <div class="popup_table_wrap">
        <table class="applicant_info_table vtable">
          <thead>
            <tr>
              <th>번호</th>
              <th>지원자 명</th>
              <th>아이디</th>
              <th>휴대폰</th>
              <th>이메일</th>
              <th>업무 분야</th>
              <th>경력</th>
              <th>지원 일자</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>
                {{ totalElements - totalPages * (currentPage - 1) - index }}
              </td>
              <td class="cursor" @click="detail(item.applyId)">
                {{ item.applyNm }}
              </td>
              <td>{{ item.applyId }}</td>
              <td>
                <span v-if="item.member">{{ item.member.mobile }}</span>
              </td>
              <td>
                <span v-if="item.member">{{ item.member.email }}</span>
              </td>
              <td></td>
              <td>
                <span v-if="item.member && item.member.careerYear"
                  >{{ item.member.careerYear }}년</span
                >
                <span v-if="item.member && item.member.careerMonth > 0"
                  >{{ item.member.careerMonth }}개월</span
                >
              </td>
              <td>{{ item.applyDtTm.substring(0, 10) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="center_btn">
          <button type="button" @click="close">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/common/http";
export default {
  props: {
    applyProjectCd: String,
  },
  data() {
    return {
      url: "/api/project/apply/",
      items: [],
      totalElements: "",
      totalPages: "",
      currentPage: 1,
    };
  },
  created() {
    this.read();
  },
  methods: {
    read() {
      http.get(`${this.url}${this.applyProjectCd}`).then((response) => {
        const { data } = response;
        this.items = data.content;
        this.totalElements = data.totalElements;
        this.totalPages = data.totalPages;
      });
    },
    detail(applyId) {
      console.log(applyId);
      this.$router.push({
        name: "MemberSeq",
        params: {
          seq: applyId,
        },
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
