<template>
  <main>
    <div class="container">
      <div class="table_wrap">
        <table class="alliance_search_table htable">
          <tr>
            <th>구분</th>
            <td>
              <select
                name="division"
                id="division"
                v-model="search_category"
                required
              >
                <option value="" disabled checked>문의 구분</option>
                <option value="">전체</option>
                <option value="alliance">제휴 문의</option>
                <option value="adver">광고 문의</option>
              </select>
            </td>
            <th class="border_left">작성일</th>
            <td>
              <date-picker
                v-model="search_start_dt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" :value="inputValue" v-on="inputEvents" />
                </template>
              </date-picker>
              ~
              <date-picker
                v-model="search_end_dt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" :value="inputValue" v-on="inputEvents" />
                </template>
              </date-picker>
            </td>
          </tr>
          <tr>
            <th>검색어</th>
            <td colspan="4">
              <input
                type="radio"
                name="myradio"
                id="all"
                value=""
                v-model="search_condition"
                checked
              />
              <label for="all">전체</label>
              <input
                type="radio"
                name="myradio"
                value="company"
                id="company"
                v-model="search_condition"
              />
              <label for="company">회사명(성명)</label>

              <input
                type="radio"
                name="myradio"
                value="email"
                id="email"
                v-model="search_condition"
              />
              <label for="email">이메일 주소</label>
              <input type="text" v-model="search_keyword" />
            </td>
          </tr>
        </table>
        <div class="center_btn">
          <button type="button" @click="formSubmit">검색</button>
        </div>
      </div>
      <div class="alliance_result table_wrap">
        <p class="total">
          검색 : <span>{{ this.totalElements }}</span
          >명 | 전체 : <span>{{ this.totalCount }}</span
          >명
        </p>
        <table class="alliance_result_table vtable">
          <thead>
            <tr>
              <th>번호</th>
              <th>구분</th>
              <th>회사명(성명)</th>
              <th>휴대폰 번호</th>
              <th>이메일 주소</th>
              <th>작성 일시</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="contentLoading">
              <td colspan="100">
                <clip-loader
                  class="spinner"
                  :loading="contentLoading"
                  :color="'#2da1dd'"
                  :size="'30px'"
                />
              </td>
            </tr>
            <tr v-else-if="!contentLoading && items.length == 0">
              <td colspan="100">등록된 파트너가 없습니다.</td>
            </tr>
            <tr v-else v-for="(item, index) in items" :key="index">
              <td>
                {{ totalElements - pageSize * (currentPage - 1) - index }}
              </td>
              <td v-if="item.allianceYn == 'Y' && item.adverYn == 'Y'">
                제휴/광고문의
              </td>
              <td v-else-if="item.allianceYn == 'Y'">제휴문의</td>
              <td v-else>광고문의</td>
              <td class="cursor" @click="detail(item)">
                {{ item.company }}
              </td>
              <td>{{ item.mobile }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.regDtTm }}</td>
            </tr>
          </tbody>
        </table>
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="totalPages ? totalPages : 1"
          use-router
          v-model="currentPage"
          :limit="10"
        ></b-pagination-nav>
      </div>
    </div>
  </main>
</template>

<script>
import AllianceDetail from "@/components/AllianceDetail.vue";
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      contentLoading: true,

      items: [],
      seq: "",
      item: {},
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      totalPages: 1,
      pageSize: 1,
      totalElements: 0,
      totalCount: 0,
      search_category: "",
      search_start_dt: this.$date().subtract(7, "days").format("YYYY-MM-DD"),
      search_end_dt: this.$date().format("YYYY-MM-DD"),
      search_condition: "",
      search_keyword: "",
    };
  },
  created() {
    this.$Progress.start();

    if (this.$route.query) {
      this.search_category = this.$route.query.search_category
        ? this.$route.query.search_category
        : "";
      this.search_start_dt = this.$route.query.search_start_dt
        ? this.$route.query.search_start_dt
        : this.search_start_dt;
      this.search_end_dt = this.$route.query.search_end_dt
        ? this.$route.query.search_end_dt
        : this.search_end_dt;
      this.search_condition = this.$route.query.search_condition
        ? this.$route.query.search_condition
        : "";
      this.search_keyword = this.$route.query.search_keyword
        ? this.$route.query.search_keyword
        : "";
    }
    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    formSubmit() {
      let params = this.getFormData();
      params.page = 1;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      if (
        "/partner?" + encodeURI(payloadString) !=
        this.$router.history.current.fullPath
      ) {
        this.$router.push({
          name: "Partner",
          query: params,
        });
      }
    },
    getFormData() {
      let params = {};
      if (this.search_category != null && this.search_category != "") {
        params.search_category = this.search_category;
      }
      if (this.search_start_dt) {
        params.search_start_dt = this.$date(this.search_start_dt).format(
          "YYYY-MM-DD"
        );
      }
      if (this.search_end_dt) {
        params.search_end_dt = this.$date(this.search_end_dt).format(
          "YYYY-MM-DD"
        );
      }
      if (this.search_condition != null && this.search_condition != "") {
        params.search_condition = this.search_condition;
      }
      if (this.search_keyword.length != 0) {
        params.search_keyword = this.search_keyword;
      }
      params.page = this.$route.query.page;

      return params;
    },
    linkGen(pageNum) {
      let params = this.getFormData();
      params.page = pageNum;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      return `?${payloadString}`;
    },
    async read() {
      this.contentLoading = true;

      let params = this.getFormData();

      await client.boardContactList(params).then(
        (response) => {
          const { data } = response;
          this.items = data.content;
          this.totalPages = data.totalPages;
          this.pageSize = data.size;
          this.totalElements = data.totalElements;
        },
        (error) => {
          console.log(error);
        }
      );

      await client.boardContactTotalCount(params).then(
        (response) => {
          const { data } = response;
          this.totalCount = data.totalCount;
        },
        (error) => {
          console.log(error);
        }
      );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    detail(item) {
      this.$modal.show(
        AllianceDetail,
        {
          item: item,
        },
        {
          width: 500,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
  },
};
</script>
