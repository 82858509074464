import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 공지사항 목록
const boardNoticeList = (params) => {
  return http.get(SERVICE_API_URL + `/board/notice`, {
    params: params,
    headers: authHeader(),
  });
};

// 공지사항 전체 데이터 수
const boardNoticeTotalCount = (params) => {
  return http.get(SERVICE_API_URL + `/board/notice/totalcount`, {
    params: params,
    headers: authHeader(),
  });
};

// 공지사항 등록
const boardNoticeReg = (params) => {
  return http.post(SERVICE_API_URL + `/board/notice`, params, {
    headers: authHeader(),
  });
};

// 공지사항 수정
const boardNoticeMod = (seq, params) => {
  return http.put(SERVICE_API_URL + `/board/notice/${seq}`, params, {
    headers: authHeader(),
  });
};

// 공지사항 보기
const boardNoticeView = (seq) => {
  return http.get(SERVICE_API_URL + `/board/notice/${seq}`, {
    headers: authHeader(),
  });
};

// 공지사항 이전글
const boardNoticePrev = (seq) => {
  return http.get(SERVICE_API_URL + `/board/notice/${seq}/prev`, {
    headers: authHeader(),
  });
};

// 공지사항 다음글
const boardNoticeNext = (seq) => {
  return http.get(SERVICE_API_URL + `/board/notice/${seq}/next`, {
    headers: authHeader(),
  });
};

// 공지사항 삭제
const boardNoticeDel = (seq) => {
  return http.delete(SERVICE_API_URL + `/board/notice/${seq}`, {
    headers: authHeader(),
  });
};

export default {
  boardNoticeList,
  boardNoticeTotalCount,
  boardNoticeReg,
  boardNoticeMod,
  boardNoticeView,
  boardNoticePrev,
  boardNoticeNext,
  boardNoticeDel,
};
