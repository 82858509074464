<template>
  <main>
    <div class="container">
      <template v-if="contentLoading">
        <clip-loader
          style="margin-top: 100px"
          class="spinner"
          :loading="contentLoading"
          :color="'#2da1dd'"
          :size="'30px'"
        />
      </template>
      <template v-else>
        <div class="table_wrap">
          <table class="pquestion_detail_table htable">
            <tr>
              <th>구분</th>
              <td v-if="item.category">
                {{ item.category.nm }}
              </td>
              <td v-else></td>
              <th>작성자(아이디)</th>
              <td>{{ item.questionNm }}</td>
              <th>작성 일시</th>
              <td>{{ item.questionDtTm }}</td>
              <th>답변 유무</th>
              <td v-if="item.answerContent">답변 완료</td>
              <td v-else>답변 전</td>
            </tr>
            <tr>
              <th>이메일 주소</th>
              <td colspan="7">{{ item.email }}</td>
            </tr>
            <tr>
              <th>제목</th>
              <td colspan="7">{{ item.questionNm }}</td>
            </tr>
            <tr>
              <th>내용</th>
              <td colspan="7">
                {{ item.questionContent }}
              </td>
            </tr>
            <tr v-if="item.attach">
              <th>첨부파일</th>
              <td colspan="7" v-if="item.attach">
                <a
                  :href="`${this.fileDown_url}${item.attachCd}`"
                  target="_blank"
                  >{{ item.attach.fileNm }}</a
                >
              </td>
              <td v-else colspan="7"></td>
            </tr>
          </table>
        </div>
        <div class="table_wrap">
          <div class="persnal_answer_complete">
            <table class="panswer_complete_table htable">
              <tr>
                <th rowspan="2" class="border_right">답변하기</th>
                <th>답변 담당</th>
                <td>{{ item.answerNm }}({{ item.answerId }})</td>
                <th>답변 일시</th>
                <td>{{ item.answerDtTm }}</td>
              </tr>
              <tr>
                <td colspan="4" class="text_left white_space">
                  {{ item.answerContent }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="right_btn">
          <button type="button" @click="list">목록</button>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      contentLoading: true,

      url: "/api/board/qna/",
      item: {},
      seq: "",
      answerContent: "",
      fileDown_url: process.env.VUE_APP_API_URL + "/api/attach/",
    };
  },
  created() {
    this.$Progress.start();

    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async read() {
      this.contentLoading = true;

      this.seq = this.$route.params.seq;
      await client.boardQnaView(this.seq).then(
        (response) => {
          const { data } = response;
          this.item = data;
        },
        (error) => {
          console.log(error);
        }
      );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    list() {
      this.$router.push({
        name: "Qna",
      });
    },
  },
};
</script>
