import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 프로젝트 구인파트 정보
const projectJobList = (project_cd, params) => {
  return http.get(SERVICE_API_URL + `/project/job/${project_cd}`, {
    params: params,
    headers: authHeader(),
  });
};

// 프로젝트 구인파트 등록
const projectJobEdit = (project_cd, params) => {
  return http.post(SERVICE_API_URL + `/project/job/${project_cd}`, params, {
    headers: authHeader(),
  });
};

export default {
  projectJobList,
  projectJobEdit,
};
