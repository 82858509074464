<template>
  <div class="client_info">
    <div class="client_info_content popup_content">
      <h3>선정자 정보</h3>
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <div class="popup_table_wrap">
        <table
          class="client_info_table popup_htable"
          v-for="(selection, idx) in selections"
          :key="idx"
        >
          <tr>
            <th>지원자 명</th>
            <td>{{ selection.applyNm }}</td>
            <th>아이디</th>
            <td>{{ selection.applyId }}</td>
            <th>이메일</th>
            <td>
              <span v-if="selection.member">
                {{ selection.member.email }}
              </span>
            </td>
            <th>업무 분야</th>
            <td>
              <span v-if="selection.selectionWork">{{
                selection.selectionWork.nm
              }}</span>
            </td>
            <th>지원 일자</th>
            <td>{{ selection.applyDtTm.substring(0, 10) }}</td>
          </tr>
          <tr>
            <th>진행 방식</th>
            <td>
              <span v-if="selection.project.progress == 1"
                >상주(파견) 희망</span
              >
              <span v-if="selection.project.progress == 2">도급 희망</span>
              <span v-if="selection.project.progress == 0">기타</span>
            </td>
            <th>등급</th>
            <td>{{ selection.selectionRank }}</td>
            <th>세부 분야</th>
            <td>
              <span v-if="selection.project.task">{{
                selection.project.task.nm
              }}</span>
            </td>
            <th>투입 일자</th>
            <td>{{ selection.selectionStartDt }}</td>
            <th>철수 일자</th>
            <td>{{ selection.selectionEndDt }}</td>
          </tr>
          <tr>
            <th>프로젝트</th>
            <td colspan="3">{{ selection.project.projectNm }}</td>
            <th>근무장소</th>
            <td>{{ selection.project.place }}</td>
            <th>월 단가</th>
            <td>{{ selection.selectionPrice | comma }}</td>
            <th>지급일</th>
            <td>{{ selection.selectionPayDt }}</td>
          </tr>
        </table>
      </div>
      <div class="center_btn">
        <button type="button" @click="close">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/common/http";

export default {
  props: {
    projectCd: String,
  },
  data() {
    return {
      url: "/api/project/apply/",
      selections: [],
    };
  },
  created() {
    this.read();
  },
  methods: {
    read() {
      http
        .get(`${this.url}${this.projectCd}/selection`)
        .then((response) => {
          const { data } = response;
          this.selections = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      this.$emit("close");
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
