<template>
  <main>
    <div class="container">
      <template v-if="contentLoading">
        <clip-loader
          style="margin-top: 100px"
          class="spinner"
          :loading="contentLoading"
          :color="'#2da1dd'"
          :size="'30px'"
        />
      </template>
      <template v-else>
        <div class="table_wrap">
          <table class="notice_detail_table popup_htable">
            <tr>
              <th>구분</th>
              <td>{{ item.topYn == "Y" ? "공지" : "일반" }}</td>
            </tr>
            <tr>
              <th>제목</th>
              <td>
                {{ item.title }}
              </td>
            </tr>
            <tr class="notice_detail_content">
              <th>내용</th>
              <td>
                <p>
                  <viewer
                    :initialValue="initialValue"
                    v-if="initialValue != null"
                  />
                </p>
              </td>
            </tr>
            <tr v-if="item.attachOne">
              <th rowspan="3">첨부파일</th>
              <td>
                <a
                  :href="`${this.fileDown_url}${item.attachCdOne}`"
                  target="_blank"
                  v-if="item.attachOne"
                  >{{ item.attachOne.fileNm }}
                </a>
              </td>
            </tr>
            <tr>
              <td v-if="item.attachCdTwo">
                <a
                  :href="`${this.fileDown_url}${item.attachCdTwo}`"
                  target="_blank"
                  v-if="item.attachTwo"
                >
                  {{ item.attachTwo.fileNm }}
                </a>
              </td>
            </tr>
            <tr>
              <td v-if="item.attachCdThree">
                <a
                  :href="`${this.fileDown_url}${item.attachCdThree}`"
                  target="_blank"
                  v-if="item.attachThree"
                >
                  {{ item.attachThree.fileNm }}
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div class="btn_wrap">
          <div>
            <button @click="deleteItem">삭제</button>
          </div>
          <div>
            <button @click="list" class="list_btn">목록</button>
            <button @click="update">수정</button>
          </div>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import { Viewer } from "@toast-ui/vue-editor";
import { mapGetters } from "vuex";
import client from "api-client";

export default {
  components: {
    viewer: Viewer,
  },
  data() {
    return {
      contentLoading: true,

      item: "",
      seq: "",
      contentEditor: "",
      initialValue: null,
      fileDown_url: process.env.VUE_APP_API_URL + "/api/attach/",
    };
  },
  methods: {
    list() {
      this.$router.push({
        name: "Notice",
      });
    },
    async read() {
      this.contentLoading = true;

      this.seq = this.$route.params.seq;

      await client.boardNoticeView(this.seq).then(
        (response) => {
          const { data } = response;
          this.item = data;
          this.initialValue = data.contentEditor;
        },
        (error) => {
          console.log("error");
          console.log(error.response.status);
        }
      );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    deleteItem() {
      if (confirm("해당 게시물을 삭제하시겠습니까?")) {
        alert("게시물이 삭제되었습니다.");
        this.delete();
      }
    },
    async delete() {
      await client.boardNoticeDel(this.seq).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.$router.push({
            name: "Notice",
          });
        },
        (error) => {
          if (error.response.status != 200) {
            console.log(error);
          }
          // console.log(error);
          // console.log(error.response.status);
        }
      );
    },
    update() {
      this.$router.push({
        name: "NoticeEditSeq",
        params: {
          seq: this.seq,
        },
      });
    },
  },
  created() {
    this.$Progress.start();

    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
