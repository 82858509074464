<template>
  <div class="alliance_detail">
    <div class="alliance_detail_content popup_content">
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <div class="alliance_popup_wrap popup_table_wrap">
        <p><strong>구분</strong> : <span>제휴 문의, 광고 문의 </span></p>
        <p>
          <strong>회사명(성명)</strong> :
          <span>{{ item.company }}</span>
        </p>
        <p>
          <strong>휴대폰 번호</strong> : <span>{{ item.mobile }}</span>
        </p>
        <p>
          <strong>이메일 주소</strong> : <span>{{ item.email }}</span>
        </p>
        <p>
          <strong>주소</strong> :
          <span>{{ item.address }}</span>
        </p>
        <p class="alliance_popup_content">
          <strong>문의 내용</strong> :
          <span>
            {{ item.content }}
          </span>
        </p>
        <p v-if="item.attach">
          <strong>첨부파일</strong> :
          <span class="cursor">
            <a :href="`${fileDown_url}${item.attachCd}`" target="_blank">
              {{ item.attach.fileNm }}
            </a>
          </span>
        </p>
      </div>
      <div class="center_btn">
        <button type="button" @click="close">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    seq: {
      type: Number,
    },
  },
  data() {
    return {
      fileDown_url: process.env.VUE_APP_API_URL + "/api/attach/",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
