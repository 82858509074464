<template>
  <main>
    <div class="container">
      <template v-if="contentLoading">
        <clip-loader
          style="margin-top: 100px"
          class="spinner"
          :loading="contentLoading"
          :color="'#2da1dd'"
          :size="'30px'"
        />
      </template>
      <template v-else>
        <div class="table_wrap">
          <table class="project_detail_table htable">
            <tr>
              <th>프로젝트 명</th>
              <td>
                <span v-if="project.projectNm">{{ project.projectNm }}</span>
                <span v-else>-</span>
              </td>
              <th>등록 일시</th>
              <td>
                <span v-if="project.regDtTm">{{ project.regDtTm }}</span>
                <span v-else>-</span>
              </td>
              <th>모집 기간</th>
              <td colspan="3">
                <span v-if="this.term > 0">D - {{ this.term }}</span>
                <span v-else-if="this.term <= 0">D - 0</span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th>진행 방식</th>
              <td>
                <span v-if="project.progress == 1">상주(파견) 희망</span>
                <span v-else-if="project.progress == 2">도급 희망</span>
                <span v-else-if="project.progress == 0">기타</span>
                <span v-else>-</span>
              </td>

              <th>업무 분야</th>
              <td>
                <span v-if="project.work">{{ project.work.nm }}</span>
                <span v-else>-</span>
              </td>
              <th>세부 분야</th>
              <td>
                <span v-if="project.task">{{ project.task.nm }}</span>
                <span v-else>-</span>
              </td>
              <th>지역 구분</th>
              <td>
                <span v-if="project.area">{{ project.area.nm }}</span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th>모집 구분</th>
              <td>
                <span v-if="project.recruit == 1">신규</span>
                <span v-else-if="project.recruit == 2">모집 중</span>
                <span v-else-if="project.recruit == 3">모집 완료</span>
                <span v-else>-</span>
              </td>

              <th>프로젝트 기간</th>
              <td>
                <span v-if="project.projectStartDt && project.projectEndDt"
                  >{{ project.projectStartDt }} ~ {{ project.projectEndDt }} /
                  {{ `${term}일` }}</span
                >
                <span v-else>-</span>
              </td>
              <th>프로젝트 금액</th>
              <td>
                <span v-if="project.projectMinPrice && project.projectMaxPrice">
                  {{ project.projectMinPrice | comma }}원~{{
                    project.projectMaxPrice | comma
                  }}원 / 월
                </span>
                <span v-else>-</span>
              </td>
              <th>현재 상태</th>
              <td colspan="3">
                <span v-if="project.state">{{ project.state }}</span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th>기타 내용</th>
              <td colspan="9">
                <span v-if="project.etcContent" class="white_space">{{
                  project.etcContent
                }}</span>
                <span v-else>-</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="table_wrap">
          <p class="result_date">
            프로젝트 의뢰 일자 : <span>{{ project.requestStartDt }}</span>
          </p>
          <table class="company_detail_table htable">
            <tr>
              <th>발주사 명</th>
              <td>
                <span v-if="project.orderingCompany">{{
                  project.orderingCompany
                }}</span>
                <span v-else>-</span>
              </td>
              <th>사업자 번호</th>
              <td>
                <span v-if="project.businessLicense">{{
                  project.businessLicense
                }}</span>
                <span v-else>-</span>
              </td>
              <th>대표번호</th>
              <td>
                <span v-if="project.mainPhone">{{ project.mainPhone }}</span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th>담당자 명</th>
              <td>
                <span v-if="project.managerNm">{{ project.managerNm }}</span>
                <span v-else>-</span>
              </td>
              <th>연락처</th>
              <td>
                <span v-if="project.contactPhone">{{
                  project.contactPhone
                }}</span>
                <span v-else>-</span>
              </td>
              <th>이메일</th>
              <td>
                <span v-if="project.email">{{ project.email }}</span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th>원 발주사</th>
              <td>
                <span v-if="project.customerCompany">{{
                  project.customerCompany
                }}</span>
                <span v-else>-</span>
              </td>
              <th>근무지</th>
              <td>
                <span v-if="project.place">{{ project.place }}</span>
                <span v-else>-</span>
              </td>
              <th>프로젝트 기간</th>
              <td>
                <span v-if="project.requestStartDt && project.requestEndDt"
                  >{{ project.requestStartDt }} ~
                  {{ project.requestEndDt }}</span
                >
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th>구인 파트</th>
              <td colspan="5">
                <p v-if="jobs.length == 0">-</p>
                <p
                  v-else
                  class="job_part"
                  v-for="(job, idx) in jobs"
                  :key="idx"
                >
                  {{ job.jobWork.nm }} / {{ job.jobRank }} /
                  {{ job.jobPrice | comma }}원 / {{ job.jobStartDt }}~{{
                    job.jobEndDt
                  }}
                </p>
              </td>
            </tr>
            <tr>
              <th>상세내용</th>
              <td colspan="5">
                <span class="white_space" v-if="project.detailContent">{{
                  project.detailContent
                }}</span>
                <span v-else>-</span>
              </td>
            </tr>
          </table>
          <div class="right_btn">
            <button class="list_btn" type="button" @click="list()">목록</button>
            <button type="button" @click="update()">수정</button>
          </div>
        </div>
        <div class="uplicant table_wrap">
          <h3>지원자 관리</h3>
          <table class="applicant_table vtable">
            <thead>
              <tr>
                <th>번호</th>
                <th>지원자 명</th>
                <th>아이디</th>
                <th>휴대폰</th>
                <th>이메일</th>
                <th>업무 분야</th>
                <th>경력</th>
                <th>경력기술서</th>
                <th>지원 일시</th>
                <th>지원자 선정</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="applies.length == 0">
                <td colspan="100">결과가 없습니다.</td>
              </tr>
              <tr
                v-else
                v-for="(apply, idx) in applies"
                :key="idx"
                class="applicant_list"
              >
                <td>
                  {{ totalElements - pageSize * (currentPage - 1) - idx }}
                </td>
                <td class="cursor" @click="detail(apply.applyId)">
                  {{ apply.applyNm }}
                </td>
                <td class="cursor" @click="detail(apply.applyId)">
                  {{ apply.applyId }}
                </td>
                <td v-if="apply.member">{{ apply.member.mobile }}</td>
                <td v-if="apply.member">{{ apply.member.email }}</td>
                <td>퍼블리싱</td>
                <td>1년2개월</td>
                <td class="cursor">
                  <a :href="`${fileDown_url}${resume}`" target="_blank">
                    <img src="@/assets/icons/document.png" alt="download" />
                  </a>
                </td>
                <td>{{ apply.applyDtTm }}</td>
                <td>
                  <button
                    type="button"
                    @click="selection(apply.seq)"
                    v-if="!apply.selectionYn"
                    class="apply_selection_btn"
                  >
                    선정
                  </button>
                  <button
                    type="button"
                    @click="unselection(apply.seq)"
                    v-if="!apply.selectionYn"
                    class="apply_unselection_btn"
                  >
                    미선정
                  </button>
                  <button
                    @click="changeToUnselection(apply.seq)"
                    v-if="apply.selectionYn == 'Y'"
                  >
                    선정
                  </button>
                  <button
                    @click="changeToSelection(apply.seq)"
                    v-if="apply.selectionYn == 'N'"
                  >
                    미선정
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import ApplicantChoose from "@/components/ApplicantChoose.vue";
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      contentLoading: true,
      fileDown_url: process.env.VUE_APP_API_URL + "/api/attach/",
      applies: [],
      project_cd: "",
      project: "",
      term: "",
      jobs: "",
      seq: "",
      totalElements: "",
      pageSize: "",
      currentPage: 1,
      applyId: "",
      resume: "",
    };
  },
  methods: {
    selection(seq) {
      this.seq = seq;
      this.$modal.show(
        ApplicantChoose,
        {
          seq: seq,
          project_cd: this.project_cd,
        },
        {
          width: 700,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    unselection(seq) {
      if (confirm("해당 회원을 선정하지 않겠습니까?")) {
        client.projectApplySelectionCancel(this.project_cd, seq).then(
          (response) => {
            console.log(response);
            this.$router.go();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    changeToUnselection(seq) {
      if (confirm("해당 회원의 선정을 취소 하시겠습니까?")) {
        client.projectApplySelectionCancel(this.project_cd, seq).then(
          (response) => {
            console.log(response);
            alert("해당 회원의 선정이 취소되었습니다.");
            this.$router.go();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    changeToSelection(seq) {
      if (confirm("해당 회원을 다시 선정하겠습니까?")) {
        this.$modal.show(
          ApplicantChoose,
          {
            seq: seq,
            project_cd: this.project_cd,
          },
          {
            width: 700,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      }
    },
    async read() {
      this.contentLoading = true;

      this.project_cd = this.$route.params.seq;

      await client.projectView(this.project_cd).then(
        (response) => {
          const { data } = response;
          this.project = data;
          this.getTerm();
        },
        (error) => {
          console.log(error);
        }
      );

      await client.projectJobList(this.project_cd).then(
        (response) => {
          const { data } = response;
          this.jobs = data;
        },
        (error) => {
          console.log(error);
        }
      );

      await client.projectApplyList(this.project_cd).then(
        (response) => {
          const { data } = response;
          this.applies = data.content;
          this.totalElements = data.totalElements;
          this.pageSize = data.size;
          this.applyId = data.content.applyId;
        },
        (error) => {
          console.log(error);
        }
      );

      await client.memberUserView(this.applyId).then(
        (response) => {
          const { data } = response;
          this.resume = data.attachCdResume;
        },
        (error) => {
          console.log(error);
        }
      );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    getTerm() {
      let txt_sdate = this.project.projectStartDt;
      txt_sdate = txt_sdate.replace("-", "/");
      let t_sdate = new Date(txt_sdate);
      let txt_edate = this.project.projectEndDt;
      txt_edate = txt_edate.replace("-", "/");
      let t_edate = new Date(txt_edate);
      let elapsedMSec = t_edate.getTime() - t_sdate.getTime();
      let elapsedDay = elapsedMSec / 1000 / 60 / 60 / 24;
      this.term = elapsedDay;
    },
    detail(applyId) {
      this.$router.push({
        name: "MemberSeq",
        params: {
          seq: applyId,
        },
      });
    },
    update() {
      this.$router.push({
        name: "ProjectEditSeq",
        params: {
          seq: this.project_cd,
        },
      });
    },
    list() {
      this.$router.push({
        name: "Project",
      });
    },
  },
  created() {
    this.$Progress.start();

    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
