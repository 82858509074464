<template>
  <div class="progress_detail">
    <div class="progress_detail_content popup_content">
      <h3>프로젝트 진행 수</h3>
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <div class="popup_table_wrap">
        <table
          :items="items"
          v-for="(item, index) in items"
          :key="index"
          class="progress_detail_table popup_htable"
        >
          <tr>
            <th>업무 분야</th>
            <td>
              <span v-if="item.selectionWork">{{ item.selectionWork.nm }}</span>
            </td>
            <th>지원 일자</th>
            <td>{{ item.applyDtTm.substring(0, 10) }}</td>
            <th>진행방식</th>
            <td>
              <span v-if="item.project && item.project.progress == 1"
                >상주(파견) 희망</span
              >
              <span v-if="item.project && item.project.progress == 2"
                >도급 희망</span
              >
              <span v-if="item.project && item.project.progress == 0"
                >기타</span
              >
            </td>
            <th>등급</th>
            <td>{{ item.selectionRank }}</td>
            <th>세부 분야</th>
            <td>
              <span v-if="item.project">{{ item.project.task.nm }}</span>
            </td>
          </tr>
          <tr>
            <th>투입 일자</th>
            <td>{{ item.selectionStartDt }}</td>
            <th>철수 일자</th>
            <td>{{ item.selectionEndDt }}</td>
            <th>월 단가</th>
            <td>{{ item.selectionPrice | comma }}</td>
            <th>지급일</th>
            <td>매 월 {{ item.selectionPayDt }}일</td>
            <th>근무 장소</th>
            <td>{{ item.project.place }}</td>
          </tr>
          <tr>
            <th>프로젝트</th>
            <td colspan="9">{{ item.project.projectNm }}</td>
          </tr>
        </table>
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalElements"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import http from "@/common/http";
export default {
  props: {
    memberId: String,
  },
  data() {
    return {
      url: "api/project/apply/user/",
      items: [],
      applyDtTm: "",
      perPage: 5,
      totalElements: "",
      currentPage: 1,
      totalPages: "",
    };
  },
  created() {
    this.read();
  },
  methods: {
    read() {
      http
        .get(`${this.url}${this.memberId}`)
        .then((response) => {
          const { data } = response;
          this.items = data.content;
          this.totalPages = data.totalPages;
          this.totalElements = data.totalElements;
          this.pageSize = data.pageSize;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      this.$emit("close");
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
