<template>
  <main>
    <div class="container">
      <template v-if="contentLoading">
        <clip-loader
          style="margin-top: 100px"
          class="spinner"
          :loading="contentLoading"
          :color="'#2da1dd'"
          :size="'30px'"
        />
      </template>
      <template v-else>
        <div class="division">
          <img
            :src="`${fileDown_url}${item.attachCdPhoto}`"
            alt="회원 사진"
            @click="detailImg(item.attachCdPhoto)"
          />
          구분 :<span>프리랜서(개인)</span>
        </div>
        <div class="table_wrap">
          <table class="member_detail_table-01 htable">
            <tr>
              <th>이름</th>
              <td>{{ item.memberNm }}</td>
              <th>아이디</th>
              <td>{{ item.memberId }}</td>
              <th>휴대폰</th>
              <td>{{ item.mobile }}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>{{ item.email }}</td>
              <th>주소</th>
              <td>{{ item.address }}</td>
              <th>계좌 관리</th>
              <td>
                <span v-if="item.bank">{{ item.bank.nm }}</span> /
                {{ item.accountNumber }}
              </td>
            </tr>
            <tr>
              <th scope="row">통장사본</th>
              <td colspan="5">
                <a
                  :href="`${fileDown_url}${item.attachCdBankbook}`"
                  target="_blank"
                  v-if="item.attachBankbook"
                >
                  {{ item.attachBankbook.fileNm }}
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div class="table_wrap">
          <table class="member_detail_table-02 htable">
            <tr>
              <th scope="row">경력 년수</th>
              <td>{{ item.careerYear }}년 {{ item.careerMonth }}개월</td>
              <th scope="row">희망 월 단가</th>
              <td>
                {{ item.minPrice | comma }}원 ~ {{ item.maxPrice | comma }}원
              </td>
              <th scope="row">KOSA 보유여부</th>
              <td>{{ item.kosaYn }}</td>
            </tr>
            <tr>
              <th scope="row">업무 분야</th>
              <td colspan="5">
                <p>- <strong>PM/기획</strong> PM, 웹 기획, 컨텐츠 기획, 기타</p>
                <p>- <strong>디자인</strong> 웹 UI, 플래쉬, sketch, 기타</p>
              </td>
            </tr>
            <tr>
              <th scope="row">희망 업무 형태</th>
              <td>
                {{ item.residentYn == "Y" ? "상주(파견) 희망 /" : "" }}
                {{ item.nonresidentYn == "Y" ? "재택 희망 /" : "" }}
                {{ item.regularYn == "Y" ? "정규직 희망 /" : "" }}
                {{ item.irrelevantYn == "Y" ? "관계없음" : "" }}
              </td>
              <th scope="row">희망 업무 지역</th>
              <td>{{ item.areaCd }}</td>
              <th scope="row">경력 기술서</th>
              <td class="member_resume">
                <a
                  :href="`${fileDown_url}${item.attachCdResume}`"
                  target="_blank"
                  v-if="item.attachResume"
                >
                  <img src="@/assets/icons/document.png" alt="경력기술서" />
                </a>
              </td>
            </tr>
            <tr>
              <th>포트폴리오 사이트</th>
              <td colspan="5">{{ item.portfolioLink }}</td>
            </tr>
            <tr>
              <th>대표 해시태그</th>
              <td colspan="5">{{ item.hashtag }}</td>
            </tr>
            <tr>
              <th>소개 글</th>
              <td colspan="5" class="white_space">
                {{ item.introduction }}
              </td>
            </tr>
          </table>
        </div>
        <div class="table_wrap">
          <div class="persnal_answer_write">
            <table class="persnal_answer_write_table htable">
              <tr>
                <th>노트</th>
                <td>
                  <textarea v-model="item.note" ref="refNote"></textarea>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="right_btn">
          <button @click="registerMemo()">노트 등록</button>
        </div>
        <div class="project_management table_wrap">
          <h3>프로젝트 관리</h3>
          <table class="project_management_table vtable">
            <thead>
              <tr>
                <th>번호</th>
                <th>업무 분야</th>
                <th>지원 일자</th>
                <th>진행방식</th>
                <th>프로젝트 명</th>
                <th>등급</th>
                <th>세부 분야</th>
                <th>투입 일자</th>
                <th>철수 일자</th>
                <th>월 단가</th>
                <th>지급일</th>
                <th>근무 장소</th>
                <th>종료 여부</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="projects.length == 0">
                <td colspan="100">결과가 없습니다.</td>
              </tr>
              <tr v-else v-for="(project, index) in projects" :key="index">
                <td>
                  {{ totalElements - pageSize * (currentPage - 1) - index }}
                </td>
                <td>
                  <span v-if="project.selectionWork"
                    >{{ project.selectionWork.nm }}
                  </span>
                </td>
                <td>{{ project.applyDtTm.slice(0, 10) }}</td>
                <td v-if="project.project">
                  <span v-if="project.project.progress == 1"
                    >상주(파견) 희망
                  </span>
                  <span v-else-if="project.project.progress == 2"
                    >도급 희망</span
                  >
                  <span v-else-if="project.project.progress == 0">기타</span>
                </td>
                <td class="cursor" @click="detail(project.projectCd)">
                  <span v-if="project.project">{{
                    project.project.projectNm
                  }}</span>
                </td>
                <td>
                  <span v-if="project.selectionRank == 'S'">특급</span>
                  <span v-else-if="project.selectionRank == 'A'">고급</span>
                  <span v-else-if="project.selectionRank == 'B'">중급</span>
                  <span v-else-if="project.selectionRank == 'C'">초급</span>
                </td>
                <td>
                  <span v-if="project.project">{{
                    project.project.task.nm
                  }}</span>
                </td>
                <td>{{ project.selectionStartDt }}</td>
                <td>{{ project.selectionEndDt }}</td>
                <td>{{ project.selectionPrice | comma }}</td>
                <td>매 월 {{ project.selectionPayDt }}일</td>
                <td>
                  <span v-if="project.project"
                    >{{ project.project.place }}
                  </span>
                </td>
                <td>
                  {{
                    new Date(project.selectionEndDt).getTime() >
                    new Date().getTime()
                      ? "진행 중"
                      : "종료"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="totalPages ? totalPages : 1"
            use-router
            v-model="currentPage"
            :limit="10"
          ></b-pagination-nav>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import UserImg from "@/components/UserImg.vue";
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      contentLoading: true,
      memberId: "",
      item: {},
      fileDown_url: process.env.VUE_APP_API_URL + "/api/attach/",
      openImg: false,
      totalPages: 1,
      totalElements: 0,
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      projects: [],
      pageSize: 1,
    };
  },
  created() {
    this.$Progress.start();
    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    async read() {
      this.contentLoading = true;

      this.memberId = this.$route.params.seq;

      await client.memberUserView(this.memberId).then(
        (response) => {
          const { data } = response;
          this.item = data;
        },
        (error) => {
          console.log(error);
        }
      );
      this.currentPage = this.$route.query.page;
      await client
        .projectApplyUser(this.memberId, { page: this.currentPage })
        .then(
          (response) => {
            const { data } = response;
            this.projects = data.content;
            this.totalPages = data.totalPages;
            this.totalElements = data.totalElements;
            this.pageSize = data.size;
          },
          (error) => {
            console.log(error);
          }
        );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
    detail(projectCd) {
      this.$router.push({
        name: "ProjectSeq",
        params: {
          seq: projectCd,
        },
      });
    },
    detailImg(attachCdPhoto) {
      this.$modal.show(
        UserImg,
        {
          attachCdPhoto: attachCdPhoto,
        },
        {
          width: 500,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    async registerMemo() {
      this.contentLoading = false;
      let params = {
        memberId: this.item.memberId,
        memberNm: this.item.memberNm,
        mobile: this.item.mobile,
        email: this.item.mobile,
        zipcode: this.item.zipcode,
        address: this.item.address,
        bankCd: this.item.bankCd,
        accountNumber: this.item.accountNumber,
        careerYear: this.item.careerYear,
        careerMonth: this.item.careerMonth,
        minPrice: this.item.minPrice,
        maxPrice: this.item.maxPrice,
        kosaYn: this.item.kosaYn,
        areaCd: this.item.areaCd,
        note: this.item.note,
      };

      await client.memberUserMod(this.item.memberId, params).then(
        (response) => {
          this.waitRequest = false;
          const { data } = response;
          console.log(data);
          alert("노트가 저장되었습니다");
          this.read();
        },
        (error) => {
          this.waitRequest = false;
          console.log(error);
        }
      );
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
