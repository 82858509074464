<template>
  <main class="login">
    <div class="login_form">
      <h3>Login admin</h3>
      <form @submit="formSubmit">
        <ValidationProvider name="아이디" ref="refId" rules="required">
          <input type="id" name="id" v-model="id" placeholder="아이디" />
        </ValidationProvider>
        <ValidationProvider name="비밀번호" ref="refPassword" rules="required">
          <input
            type="password"
            name="password"
            v-model="password"
            placeholder="비밀번호"
          />
        </ValidationProvider>
        <button type="submit">로그인</button>
      </form>
    </div>
  </main>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      id: "",
      password: "",
    };
  },
  methods: {
    async formSubmit(evt) {
      evt.preventDefault();
      const refId = await this.$refs.refId.validate();
      if (!refId.valid) {
        alert(refId.errors[0]);
        return false;
      }
      const refPassword = await this.$refs.refPassword.validate();
      if (!refPassword.valid) {
        alert(refPassword.errors[0]);
        return false;
      }

      this.$store
        .dispatch("login", {
          member_id: this.id,
          member_pw: this.password,
        })
        .then((response) => {
          if (response.status == 200) {
            this.getMember(this.id);
          }
        })
        .catch((message) => {
          alert(message);
          /*
          this.$modal.show(
            Alert,
            {
              alert_name: "알림",
              alert_contnet: message
            },
            {
              height: "auto"
            },
            {
              "before-open": () => {},
              "before-close": () => {}
            }
          );
          */
        });

      return true;
    },
    async getMember(id) {
      await this.$store
        .dispatch("member", {
          member_id: id,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({
              name: "Member",
            });
          }

          this.waitRequest = false;
        })
        .catch((response) => {
          alert(response.data.message);
          /*
          this.$modal.show(
            Alert,
            {
              alert_name: "알림",
              alert_contnet: message
            },
            {
              height: "auto"
            },
            {
              "before-open": () => {},
              "before-close": () => {}
            }
          );
          */
          this.waitRequest = false;
        });
    },
  },
};
</script>
