import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../i18n";

// layout
import BasicLayout from "../layout/BasicLayout.vue";
import AuthLayout from "../layout/AuthLayout.vue";

// store
import store from "../store";

let title = "블루버튼 관리도구";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        meta: {
          authRequired: false,
          title: title + "로그인",
          breadcrumb: [{ name: "로그인", link: "" }],
        },
        component: () => import("../views/Login/index.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/member",
    component: BasicLayout,
    children: [
      {
        path: "/member",
        name: "Member",
        meta: {
          authRequired: true,
          title: title + "회원 관리",
          breadcrumb: [{ name: i18n.t("members"), link: "" }],
        },
        component: () => import("@/views/Member/List/index.vue"),
      },
      {
        path: "/member/:seq",
        name: "MemberSeq",
        meta: {
          authRequired: true,
          title: title + "회원 관리",
          breadcrumb: [{ name: i18n.t("members"), link: "" }],
        },
        component: () => import("@/views/Member/View/index.vue"),
      },

      {
        path: "/project",
        name: "Project",
        meta: {
          authRequired: true,
          title: title + "프로젝트 관리",
          breadcrumb: [{ name: i18n.t("project"), link: "" }],
        },
        component: () => import("@/views/Project/List/index.vue"),
      },
      {
        path: "/project/edit",
        name: "ProjectEdit",
        meta: {
          authRequired: true,
          title: title + "프로젝트 관리",
          breadcrumb: [{ name: i18n.t("project"), link: "" }],
        },
        component: () => import("@/views/Project/Edit/index.vue"),
      },
      {
        path: "/project/edit/:seq",
        name: "ProjectEditSeq",
        meta: {
          authRequired: true,
          title: title + "프로젝트 관리",
          breadcrumb: [{ name: i18n.t("project"), link: "" }],
        },
        component: () => import("@/views/Project/Edit/index.vue"),
      },
      {
        path: "/project/:seq",
        name: "ProjectSeq",
        meta: {
          authRequired: true,
          title: title + "프로젝트 관리",
          breadcrumb: [{ name: i18n.t("project"), link: "" }],
        },
        component: () => import("@/views/Project/View/index.vue"),
      },
      {
        path: "/notice",
        name: "Notice",
        meta: {
          authRequired: true,
          title: title + "공지사항 관리",
          breadcrumb: [{ name: i18n.t("notice"), link: "" }],
        },
        component: () => import("@/views/Notice/List/index.vue"),
      },
      {
        path: "/notice/edit",
        name: "NoticeEdit",
        meta: {
          authRequired: true,
          title: title + "공지사항 관리",
          breadcrumb: [{ name: i18n.t("notice"), link: "" }],
        },
        component: () => import("@/views/Notice/Edit/index.vue"),
      },
      {
        path: "/notice/edit/:seq",
        name: "NoticeEditSeq",
        meta: {
          authRequired: true,
          title: title + "공지사항 관리",
          breadcrumb: [{ name: i18n.t("notice"), link: "" }],
        },
        component: () => import("@/views/Notice/Edit/index.vue"),
        props: true,
      },
      {
        path: "/notice/:seq",
        name: "NoticeSeq",
        meta: {
          authRequired: true,
          title: title + "공지사항 관리",
          breadcrumb: [{ name: i18n.t("notice"), link: "" }],
        },
        component: () => import("@/views/Notice/View/index.vue"),
      },
      {
        path: "/qna",
        name: "Qna",
        meta: {
          authRequired: true,
          title: title + "1:1 문의하기 관리",
          breadcrumb: [{ name: i18n.t("1:1 question"), link: "" }],
        },
        component: () => import("@/views/Qna/List/index.vue"),
      },
      {
        path: "/qna/:seq",
        name: "QnaSeq",
        meta: {
          authRequired: true,
          title: title + "1:1 문의하기 관리",
          breadcrumb: [{ name: i18n.t("1:1 question"), link: "" }],
        },
        component: () => import("@/views/Qna/View/index.vue"),
      },
      {
        path: "/qna/edit/:seq",
        name: "QnaEditSeq",
        meta: {
          authRequired: true,
          title: title + "1:1 문의하기 관리",
          breadcrumb: [{ name: i18n.t("1:1 answers"), link: "" }],
        },
        component: () => import("@/views/Qna/Edit/index.vue"),
      },
      {
        path: "/partner",
        name: "Partner",
        meta: {
          authRequired: true,
          title: title + "제휴.광고문의 관리",
          breadcrumb: [{ name: i18n.t("contact"), link: "" }],
        },
        component: () => import("@/views/Partner.vue"),
      },
      {
        path: "/mailing",
        name: "Mailing",
        meta: {
          authRequired: true,
          title: title + "메일링 관리",
          breadcrumb: [{ name: i18n.t("mailing"), link: "" }],
        },
        component: () => import("@/views/Mailing.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "router-link-active",
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["isAuthenticated"];
  console.log(from);
  if (
    to.matched.some(function (routeInfo) {
      return routeInfo.meta.authRequired;
    })
  ) {
    // 인증이 필요한 페이지일 경우 인증 체크
    if (isAuthenticated) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    if (isAuthenticated) {
      next({ name: "Member" });
    } else {
      next();
    }
  }
});

export default router;
