<template>
  <main>
    <div class="container">
      <div class="mailing table_wrap">
        <table class="mailing_table vtable">
          <thead>
            <tr>
              <th>번호</th>
              <th>이메일 주소</th>
              <th>신청 일시</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="contentLoading">
              <td colspan="100">
                <clip-loader
                  class="spinner"
                  :loading="contentLoading"
                  :color="'#2da1dd'"
                  :size="'30px'"
                />
              </td>
            </tr>
            <tr v-else-if="!contentLoading && emails.length == 0">
              <td colspan="100">등록된 메일링이 없습니다.</td>
            </tr>

            <tr v-else v-for="(email, index) in emails" :key="index">
              <td>
                {{ totalElements - pageSize * (currentPage - 1) - index }}
              </td>
              <td>{{ email.email }}</td>
              <td>{{ email.regDtTm }}</td>
            </tr>
          </tbody>
        </table>
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="totalPages"
          use-router
          v-model="currentPage"
          :limit="10"
        ></b-pagination-nav>
      </div>
    </div>
  </main>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      contentLoading: true,
      emails: [],
      currentPage: 1,
      totalPages: 1,
      pageSize: 30,
      totalElements: 0,
      emailArr: [],
    };
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    async read() {
      this.contentLoading = true;

      this.currentPage = this.$route.query.page;

      await client
        .mailingPaging({
          page: this.$route.query.page,
        })
        .then(
          (response) => {
            const { data } = response;
            this.emails = data.content;
            this.totalPages = data.totalPages;
            this.totalElements = data.totalElements;
          },
          (error) => {
            console.log(error);
          }
        );

      setTimeout(() => {
        this.contentLoading = false;
      }, 500);
    },
  },
  created() {
    this.$Progress.start();

    this.read();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
