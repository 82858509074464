<!-- table_area -->
<template>
  <span v-if="useClick" class="tbl_img_01" @click.prevent.stop="handleClick">
    <img
      v-lazy="data"
      style="width: auto; max-width: 120px; height: 100%"
      alt="스탬프"
    />
  </span>
  <span v-else class="tbl_img_01" style="cursor: pointer">
    <img
      v-lazy="data"
      style="width: auto; max-width: 120px; height: 100%"
      alt="스탬프"
    />
  </span>
</template>
<!-- //table_area -->

<script>
export default {
  name: "BaseTableImg",
  components: {
    /**
     * components
     */
  },
  props: {
    id: {
      type: Number,
      default: () => 0,
      description: "",
    },
    item: {
      type: Object,
      default: () => {},
      description: "",
    },

    field: {
      type: Object,
      default: () => {},
      description: "",
    },

    data: {
      type: [String, Object],
      default: () => {},
      description: "",
    },

    useClick: {
      type: Boolean,
      default: () => false,
      description: "",
    },
  },
  data() {
    /**
     * data
     */
    return {};
  },
  created() {
    /**
     * created
     */
  },
  mounted() {
    /**
     * mounted
     */
  },
  computed: {
    /**
     * computed
     */
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */
    handleClick(event) {
      this.$emit("click", event, this.item, this.field);
    },
  },
};
</script>
