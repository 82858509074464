<template>
  <div class="member_img_popup" name="userImg">
    <div class="member_img_pcontent popup_content">
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <img :src="`${this.url}${this.attachCdPhoto}`" alt="회원 사진" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attachCdPhoto: {
      type: String,
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL + "/api/attach/",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
