<template>
  <div id="app">
    <div class="app_inner">
      <I18n />
      <!-- <SideNav /> -->
      <base-side-bar :logo="logo" :menus="menus" />
      <div class="header">
        <h1 class="title">
          <span :class="{ single: subtitle.length == 0 }">{{ title }}</span>
          {{ subtitle }}
        </h1>
        <ul class="member">
          <li class="member_box">
            <!-- <a href="#none">{{ getUser.name }}({{ getUser.loginid }})님</a> -->
            <span> {{ getMember.memberNm }}</span
            >님
          </li>
          <li class="member_box">
            <a href="#" @click.prevent.stop="logout">로그아웃</a>
          </li>
        </ul>
      </div>
      <div id="content">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import "@/assets/css/style.css";
// import SideNav from "@/components/SideNav.vue";
import I18n from "@/components/I18n.vue";
import BaseSideBar from "../components/core/BaseSideBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "BasicLayout",
  components: {
    // SideNav,
    I18n,
    BaseSideBar,
  },
  data() {
    return {
      memberInfo: {},
      logo: {
        src: "text_logo.png",
        text: "블루버튼",
      },
      menus: [
        {
          label: this.$t("members"),
          fontIcon: {
            style: "fas",
            icon: "fa-user",
          },
          router: {
            name: "Member",
          },
        },
        {
          label: this.$t("project"),
          fontIcon: {
            style: "fas",
            icon: "fa-list-check",
          },
          router: {
            name: "Project",
          },
        },
        {
          label: this.$t("enter"),
          fontIcon: {
            style: "fas",
            icon: "fa-circle-exclamation",
          },
          show: false,
          child: [
            {
              label: this.$t("notice"),
              router: {
                name: "Notice",
              },
            },
            {
              label: this.$t("1:1 question"),
              router: {
                name: "Qna",
              },
            },
            {
              label: this.$t("contact"),
              router: {
                name: "Partner",
              },
            },
          ],
        },
        {
          label: this.$t("mailing"),
          fontIcon: {
            style: "fas",
            icon: "fa-envelope",
          },
          router: {
            name: "Mailing",
          },
        },
      ],
    };
  },
  watch: {
    $route(to) {
      this.openNav = false;
      this.breadcrumb(to.meta.breadcrumb);
    },
  },
  created() {
    this.breadcrumb(this.$route.meta.breadcrumb);
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["getMember"]),
  },
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$router.push({
              name: "Login",
            });
          }
        })
        .catch((message) => {
          this.waitRequest = false;
          alert(message);
          /*
          this.$modal.show(
            Alert,
            {
              alert_name: "알림",
              alert_contnet: message
            },
            {
              height: "auto"
            },
            {
              "before-open": () => {},
              "before-close": () => {}
            }
          );
          */
        });
    },
    breadcrumb(breadcrumb) {
      // console.log(breadcrumb);
      this.title = breadcrumb[breadcrumb.length - 1].name;
      this.subtitle = "";
      if (1 < breadcrumb.length) {
        breadcrumb.forEach((item, index) => {
          if (index == 0) {
            this.title = item.name;
          } else {
            if (this.subtitle.length == 0) {
              this.subtitle = item.name;
            } else {
              this.subtitle = `${this.subtitle} > ${item.name}`;
            }
          }
        });
      }
    },
  },
};
</script>

<style>
#app {
  /* width: 100%;
  min-height: 100vh;
  min-width: 1920px;
  max-width: 1920px;
  position: relative; */
  background-color: #f6f6f6;
  font-size: 15px;
  width: 100%;
  min-width: 1200px;
  position: relative;
  font-family: "Noto Sans KR", sans-serif;
}
</style>
