import Crypto from "@/common/crypto";
const ID_KEY = "info";
// import { ID_MEMBER } from "@/constants";

export const get = (key) => {
  try {
    /*
    let data = Crypto.cryptoAesDecrypt(window.localStorage.getItem(ID_KEY));
    data = JSON.parse(data);
     if (Object.prototype.hasOwnProperty.call(data, key)) {
      return data[key];
    } else {
      return "";
    }
    */
    if (window.localStorage.getItem(key)) {
      return JSON.parse(window.localStorage.getItem(key));
    } else {
      return "";
    }
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const save = (key, value) => {
  try {
    let data = {};
    if (window.localStorage.getItem(ID_KEY)) {
      data = Crypto.cryptoAesDecrypt(window.localStorage.getItem(ID_KEY));
      data = JSON.parse(data);
      if (!data) {
        data = {};
      }
    } else {
      data = {};
    }

    data[key] = value;
    data = Crypto.cryptoAesEncrypt(JSON.stringify(data));
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
};

export const remove = (key) => {
  window.localStorage.removeItem(key);
  try {
    let data = JSON.parse(window.localStorage.getItem(ID_KEY));
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      delete data[key];
      window.localStorage.setItem(ID_KEY, JSON.stringify(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const destroy = () => {
  console.log("destroy");
  window.localStorage.removeItem(ID_KEY);
};

export default {
  get,
  save,
  remove,
  destroy,
};
