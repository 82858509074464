<template>
  <div class="project_end_date">
    <div class="project_end_date_content popup_content">
      <h3>프로젝트 종료 일정</h3>
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <div class="popup_table_wrap">
        <table class="project_end_date_table popup_htable">
          <tr>
            <th>업무 분야</th>
            <td>
              <span v-if="item.selectionWork">
                {{ item.selectionWork.nm }}
              </span>
            </td>
            <th>지원 일자</th>
            <td>{{ applyDtTm }}</td>
            <th>진행방식</th>
            <td>
              <span v-if="item.project && item.project.progress == 1"
                >상주(파견) 희망</span
              >
              <span v-if="item.project && item.project.progress == 2"
                >도급 희망</span
              >
              <span v-if="item.project && item.project.progress == 0"
                >기타</span
              >
            </td>
            <th>등급</th>
            <td>{{ item.selectionRank }}</td>
            <th>세부 분야</th>
            <td>
              <span v-if="item.project">{{ item.project.task.nm }}</span>
            </td>
          </tr>
          <tr>
            <th>투입 일자</th>
            <td>{{ item.selectionStartDt }}</td>
            <th>철수 일자</th>
            <td>{{ item.selectionEndDt }}</td>
            <th>월 단가</th>
            <td>{{ item.selectionPrice | comma }}</td>
            <th>지급일</th>
            <td>매 월 {{ item.selectionPayDt }}일</td>
            <th>근무 장소</th>
            <td>
              <span v-if="item.project">{{ item.project.place }}</span>
            </td>
          </tr>
          <tr>
            <th>프로젝트</th>
            <td colspan="9">
              <span v-if="item.project">
                {{ item.project.projectNm }}
              </span>
            </td>
          </tr>
        </table>
        <div class="center_btn">
          <button type="button" @click="close">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/common/http";
export default {
  props: {
    memberId: String,
  },
  data() {
    return {
      url: "api/project/apply/user/",
      item: {},
      applyDtTm: "",
    };
  },
  created() {
    this.read();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    read() {
      http
        .get(`${this.url}${this.memberId}/end`)
        .then((response) => {
          const { data } = response;
          this.item = data;
          this.applyDtTm = data.applyDtTm;
          this.applyDtTm = this.applyDtTm.substring(0, 10);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
